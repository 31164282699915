import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { finalize } from 'rxjs/operators';

import { RewardsDetail } from '@app/accounts/models/rewards-detail.model';
import { AccountsService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';
import { NavigationIcons } from '@shared/enums';
import { ServiceResult } from '@shared/models';

import { AccountInterest } from '../../models';

@Inject('accountsService')
@Component({
  selector: 'app-deposit-interest',
  templateUrl: './deposit-interest.component.html',
  styleUrls: ['./deposit-interest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositInterestComponent implements OnInit {
  @Input() interest: Partial<AccountInterest>;
  @Input() isRewardsAccount = false;
  @Input() accountNumber: string;
  @Output() openRewards = new EventEmitter<never>();
  interestMaximumRate = 0;
  showRewardsLink = false;
  isExpanded = false;
  isLoading = true;
  iconProps = {
    color: 'var(--link)',
    chevronDownIcon: NavigationIcons.ChevronDown,
    chevronUpIcon: NavigationIcons.ChevronUp,
    style: {
      width: '1rem',
      height: '1rem',
      strokeWidth: '2px',
    },
  };
  interestRewardsAPY = '0.00%';
  get accruedInterest() {
    return this.interest?.accruedInterest ? this.interest.accruedInterest : 0;
  }

  get interestEarnedYtd() {
    return this.interest?.interestEarnedYtd
      ? this.interest.interestEarnedYtd
      : 0;
  }

  get previousYearInterest() {
    return this.interest?.previousYearInterest
      ? this.interest.previousYearInterest
      : 0;
  }
  get getInterestAPY() {
    return this.interestAPY;
  }
  get interestAPY() {
    const apy = this.interest?.apy ?? 0;
    if (apy <= 0) {
      // Return 0.00% if the APY is less than or equal to zero
      return '0.00%';
    } else {
      // Return the APY as a percentage with two decimal places rounding down
      return (apy * 100).toFixed(2) + '%';
    }
  }

  constructor(
    private accountsService: AccountsService,
    private serviceHelper: ServiceHelper,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.isRewardsAccount) {
      this.accountsService
        .getRewardsInfoByAccountNumber(this.accountNumber)
        .pipe(
          finalize(() => {
            this.cd.markForCheck();
          })
        )
        .subscribe({
          next: (i: ServiceResult<RewardsDetail>) => {
            if (i.data) {
              const apy = i.data.rewardsCheckingCharts[0].overallEarnedAPY;
              this.interestMaximumRate =
                i.data.rewardsCheckingCharts[0].overallPossibleAPY;
              this.showRewardsLink = this.interestMaximumRate > apy;
              this.interestRewardsAPY =
                apy >= 0
                  ? apy.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + '%'
                  : '0.00%';
            }
          },
          error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        });
    }
  }
}
