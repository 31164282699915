<div class="tx-aggregation-filters">
  <div>
    <ng-content></ng-content>
  </div>
  <div
    [ngClass]="{
      'tx-aggregation-filters__show-section frow between middle': displayFilters,
      'tx-aggregation-filters__show-section': inModal,
      'tx-aggregation-filters__hide-section': inModal === displayFilters
    }"
    class="tx-aggregation-filters__group"
  >
    <!--Filter Transactions-->
    <app-tx-filter
      class="tx-aggregation-filters__item--group tx-aggregation-filters__item--flx"
      [ngClass]="{ 'tx-aggregation-filters--in-modal': inModal }"
      [type]="'dropdown'"
      [value]="filters.transactionType"
      [isDisabled]="isLoading"
      [options]="transactionsType"
      [filterName]="'transactionType'"
      (onValueChange)="setFilterValue($event.value, 'transactionType')"
    ></app-tx-filter>

    <!--Filter By Time Period-->
    <app-tx-filter
      class="tx-aggregation-filters__item--group tx-aggregation-filters__item--flx"
      [ngClass]="{ 'tx-aggregation-filters--in-modal': inModal }"
      [type]="'dropdown'"
      [value]="filters.days"
      [isDisabled]="isLoading"
      [options]="timePeriodType"
      [filterName]="'days'"
      (onValueChange)="setFilterValue($event.value, 'days')"
    ></app-tx-filter>

    <!--Filter by date range -->
    <app-date-range-filter
      class="tx-aggregation-filters__item--group tx-aggregation-filters__item--flx"
      [ngClass]="{ 'tx-aggregation-filters--in-modal': inModal }"
      [isDisabled]="isLoading"
      [control]="filter.get('dateRange')"
    ></app-date-range-filter>

    <!-- Filter by amount range -->
    <popover-comparer
      class="tx-aggregation-filters__item--group tx-aggregation-filters__item--flx"
      [ngClass]="{ 'tx-aggregation-filters--in-modal': inModal }"
      [title]="'Amount Range'"
      [elementType]="'button'"
      [elementDescription]="'Amount Range'"
      [model]="filters.amountRange"
      [placement]="'bottom'"
      [placeholder]="'$'"
      [inputClasses]="'uk-input'"
      [buttonClasses]="'uk-btn lg tx-aggregation-filters__btn'"
      [elementActive]="filterIsActive('amountRange')"
      [activeBgColor]="'tx-aggregation-filters__btn--active'"
      [filterTypeName]="'amountRange'"
      (onOk)="setFilterValue($event, 'amountRange')"
      (onClear)="clearFilter('amountRange')"
      [clearText]="'Clear Filters'"
    ></popover-comparer>

    <!-- Filter by check range -->
    <popover-comparer
      class="tx-aggregation-filters__item--group tx-aggregation-filters__item--flx"
      [ngClass]="{ 'tx-aggregation-filters--in-modal': inModal }"
      [title]="'Check Range'"
      [elementType]="'button'"
      [elementDescription]="'Check Range'"
      [model]="filters.checkRange"
      [placeholder]="'#'"
      [placement]="'bottom'"
      [inputClasses]="'uk-input'"
      [buttonClasses]="'uk-btn lg tx-aggregation-filters__btn'"
      [elementActive]="filterIsActive('checkRange')"
      [activeBgColor]="'tx-aggregation-filters__btn--active'"
      [filterTypeName]="'checkRange'"
      (onOk)="setFilterValue($event, 'checkRange')"
      (onClear)="clearFilter('checkRange')"
      [clearText]="'Clear Filters'"
    ></popover-comparer>
  </div>
</div>
