import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  BrandingSettingsFacade,
  BrandingStateType,
} from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import {
  Uk2TooltipPlacementEnum,
  Uk2TooltipTriggerEnum,
  Uk2TooltipStrategyEnum,
  Uk2TooltipSizeEnum,
} from '@axos/uikit-v2-lib';
import { DefaultAdvisorLogo } from '@legacy/dashboard/account-aggregation/typings';
import { ModalService } from '@legacy/services/modal.service';
import { ProviderService } from '@legacy/services/provider.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Advisor } from '../../../account-details/core';
import { SharedAccountsFacade } from '../../../account-details/facade/shared-accounts.facade';

@Component({
  selector: 'app-share-account-details',
  templateUrl: './share-account-details.component.html',
  styleUrls: ['./share-account-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareAccountDetailsComponent implements OnInit, AfterViewInit {
  @Input() shareAccount: boolean;
  @Input() authorizeTransfers: boolean;
  @Input() displayShareAccount: boolean;
  @Input() displayAuthorizeTransfer: boolean;
  @Input() isRedesign: boolean;
  @Input() accountId: string;
  advisors: Advisor[] = [];
  uk2IsLoading = false;
  size: Uk2TooltipSizeEnum = Uk2TooltipSizeEnum.large;
  bodyText: string;
  shareAccountText = `<strong>Sharing account details</strong> grants your advisor access to view its information.`;
  authorizingTransferText = `<strong>Authorizing transfers</strong> grants your advisor the ability to move funds between this account and your
  advisor-managed account.`;
  displayCloseButton = false;
  svgIconName = 'uk2-info-circle';
  arrowOffset = 0;
  placement: Uk2TooltipPlacementEnum = Uk2TooltipPlacementEnum.topStart;
  desktopOpenMode: Uk2TooltipTriggerEnum = Uk2TooltipTriggerEnum.hover;
  strategy: Uk2TooltipStrategyEnum = Uk2TooltipStrategyEnum.absolute;
  providers: Provider[];
  allBrandingSettings: BrandingStateType[] = [];
  allRiaAccounts: RiaType[] = [];
  hasAccount = false;
  private destroy$ = new Subject<void>();

  constructor(
    private providerService: ProviderService,
    private readonly brandingSettingsFacade: BrandingSettingsFacade,
    private readonly riaFacade: RiaFacade,
    private sharedAccountsFacade: SharedAccountsFacade,
    private modalService: ModalService
  ) {}
  ngOnInit() {
    this.getProviders();
    this.brandingSettingsFacade.allBrandingSettings$.subscribe(brandings => {
      this.allBrandingSettings = brandings;
    });

    this.riaFacade.allRias$.subscribe(rias => {
      this.allRiaAccounts = rias;
    });
    this.getSharedAccounts();
  }

  ngAfterViewInit() {
    this.getTextForTooltip();
  }

  getProviders() {
    this.providerService.getProviders().then((res: OlbResponse<Provider[]>) => {
      this.providers = res.data;
    });
  }

  getAasImage(advisor: Advisor) {
    const regexValue = /url\('([^']+)'\)/;
    const secondaryLogoText = 'secondaryLogo';
    const brand = this.allBrandingSettings?.find(
      brand => brand.name === advisor.brandingName
    );
    const ria = this.allRiaAccounts?.find(
      ria => ria.riaId === advisor.advisorId.toString()
    );
    const secondaryLogo = brand?.settings.find(
      settings => settings.name === secondaryLogoText
    );
    if (secondaryLogo && ria.useCustomSecondaryLogo) {
      return secondaryLogo.value.match(regexValue)[1];
    }

    return DefaultAdvisorLogo;
  }
  openModalFromComponent(event: any, account: Advisor) {
    let checkbox = event.target;
    if (!checkbox.checked) {
      this.showShareAccountAAsModal(account);
    }
  }

  private showShareAccountAAsModal(account: Advisor) {
    this.modalService.show(
      { keyboard: false },
      {
        hasIcon: true,
        icon: 'bofi-warning',
        hasHeaderText: false,
        okText: 'Yes, Turn off Sharing',
        cancelText: 'No, Nevermind',
        hasClose: false,
        hasCancelButton: true,
        bodyText: `<h3>Disable Account Details Sharing?</h3>
          <div class='funding__body'>Sharing your account details with advisors helps them
          better tailor a plan for you. Are you sure you want to disable account sharing with ${account.advisorName}?
          </div>`,
        okButtonClass: 'uk-btn solid secondary lg sharing-account-button',
        cancelButtonClass: 'uk-btn outline primary lg sharing-account-button',
      }
    );
  }

  private getTextForTooltip() {
    this.bodyText =
      this.shareAccount && this.authorizeTransfers
        ? this.shareAccountText + `<br /><br />` + this.authorizingTransferText
        : this.shareAccount
        ? this.shareAccountText
        : this.authorizingTransferText;
  }

  private getSharedAccounts() {
    this.sharedAccountsFacade.sharedAccounts$
      .pipe(
        filter(state => state !== undefined && state !== null),
        takeUntil(this.destroy$)
      )
      .subscribe(x => {
        this.advisors = x?.sharedAccounts?.accounts.find(
          x => x.accountId === this.accountId
        )?.advisors;
        this.hasAccount = this.advisors?.length !== 0;
      });
  }
}
