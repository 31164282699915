import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ITransactionService } from 'services/typings/ITransactionService';

import { UserProfileViews } from '@app/user-profile/enums';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { sanitizePhone } from '@app/user-profile/utils';
import { AppSettings } from '@core/enums';

import { Inject } from '../../decorators/decorators';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { WireTransfer, WireTransferDestination } from '../typings/WireTransfer';
import { CachedAccountsService } from './../../services/cached-accounts.service';
@Inject(
  'cookieHelper',
  'cachedAccountsService',
  '$state',
  '$rootScope',
  '$scope',
  'transactionService',
  '$uibModal',
  '$stateParams',
  'env',
  'ngrxStore'
)
export class WireTransfersController {
  get purposeCount(): number {
    return this.transfer && this.transfer.purpose
      ? this.transfer.purpose.length
      : 0;
  }
  internalFromAccountsArray: any[] = [];
  fromAccountsArray: NewOlbAccount[] = [];
  transfer: WireTransfer;
  transferDestiny = WireTransferDestination;
  amountDisabled = false;
  selectedFromAccountValid = true;
  isLoadingFromAcc = false;
  isLoadingFees = false;
  amountValid = true;
  amountExced = false;
  amountExcedLimit = false;
  purposeValid = true;
  purposeBlank = false;
  amountL: string;
  totalAmount = 0;
  transferLimit = 0;
  brand: string;
  isProfileLoading = true;
  isWireFeeCalculated = false;
  isPhoneMissing: boolean;
  inputPattern = /^[0-9 a-zA-Z \-,.()"#%\+='<>\$!?;&@:_\\\/\r\n]*$/;

  private listeners: Function[] = [];
  constructor(
    private cookieHelper: CookieHelper,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly state: ng.ui.IStateService,
    private root: ng.IRootScopeService,
    private scope: ng.IScope,
    private transactionService: ITransactionService,
    private uibModal: ng.ui.bootstrap.IModalService,
    private params: ng.ui.IStateParamsService,
    private readonly env: OlbSettings,
    private readonly store: Store
  ) {}

  /** Initializes the controller. */
  $onInit(): void {
    this.brand = this.env.brand;
    this.isLoadingFromAcc = true;
    this.transfer = new WireTransfer();
    this.transfer.closeAccount = false;
    this.resetRootFeeData();

    const notifier = new Subject();

    this.store
      .select(getProfileDetails)
      .pipe(
        filter(data => data !== null),
        takeUntil(notifier)
      )
      .subscribe(data => {
        this.isPhoneMissing = !sanitizePhone(data.cellPhone1);
        this.isProfileLoading = false;
        notifier.next();
        notifier.complete();
      });

    // check if the balances already calculate
    if (this.root.hasOwnProperty('balancesAvailable')) this.loadAccounts();

    // wait if the balances for the accounts are calulate, then load the accounts
    this.listeners.push(
      this.root.$on('balancesAvailable', this.loadAccounts.bind(this)),
      this.scope.$on(
        '$stateChangeSuccess',
        this.onStateChangeSuccess.bind(this)
      )
    );

    this.parseBalance();
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  loadAccounts(): void {
    const {
      internalAccounts,
    } = this.cachedAccountsService.getWireFromAccounts();
    this.internalFromAccountsArray = internalAccounts;
    this.fromAccountsArray = [];
    if (this.internalFromAccountsArray != null) {
      this.internalFromAccountsArray.forEach(value => {
        this.fromAccountsArray.push({
          accountType: value.accountType,
          accountTypeCode: value.accountTypeCode,
          availableBalance: value.availableBalance,
          availableBalanceDisplay:
            '(Avail. Bal: ' +
            value.availableBalance.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }) +
            ')',
          id: value.id,
          nickname: value.nickname,
          accountNumber: value.accountNumber,
          productType: value.productType,
          Type: 'Internal Accounts',
          isExternal: false,
          isDeposit: value.isDeposit,
          isLoan: value.isLoan,
          principalAndInterest: value.principalAndInterest,
          sbbUserCif: value.sbbUserCif,
          isSBB: value.isSBB,
        });
      });
      this.isLoadingFromAcc = false;
    }
  }

  changeBalance(): void {
    if (this.amountDisabled) {
      this.amountDisabled = !this.amountDisabled;
      this.useAvailableBalance();
    } else {
      this.amountL = '$0.00';
      this.transfer.amount = 0;
      this.amountExced = false;
      this.amountExcedLimit = false;
      this.totalAmount = this.transfer.wireFee;
    }
    this.selectedFromAccountValid = true;
    this.calculateFeesForAccount();
  }

  useAvailableBalance(): void {
    this.toogleAmountDisabled();
    this.parseBalance();
  }

  toogleAmountDisabled(): void {
    this.amountDisabled = !this.amountDisabled;
  }

  parseBalance(): void {
    this.setWireLimit();
    this.setWireFee();
    this.defaultWireFeeCheck();
    this.amountExced = false;
    this.amountExcedLimit = false;

    if (this.amountDisabled) {
      this.transfer.amount =
        this.transfer.selectedFromAccount.availableBalance -
        this.transfer.wireFee;
    }

    // Ensure there are no precision errors
    this.transfer.amount = parseFloat(
      this.truncate(this.transfer.amount ?? 0, 2).toFixed(2)
    );

    this.totalAmount = this.transfer.wireFee + +(this.transfer.amount ?? 0);

    if (this.totalAmount > this.transfer.selectedFromAccount?.availableBalance)
      this.amountExced = true;
    else {
      this.amountExced = false;
      this.amountValid = true;
    }
  }

  submit(): void {
    this.setValidations();
    if (
      this.selectedFromAccountValid &&
      this.amountValid &&
      this.purposeValid &&
      !this.purposeBlank &&
      !this.amountExced &&
      !this.amountExcedLimit
    ) {
      this.state.go('udb.transfers.wireTransfers.recipientDetails', {
        wiretransfer: this.transfer,
      });
    }
  }

  displayAboutModal(): void {
    this.uibModal.open({
      controllerAs: '$ctrl',
      controller: 'WireTransfersAboutController',
      templateUrl:
        'transfers/wire-transfers/wire-transfers-about-modal/wire-transfers-about-modal.tpl.html',
      windowClass: 'modal-service',
    });
  }

  setValidationAmount(): void {
    this.amountValid = this.transfer.amount > 0;

    if (!this.transfer.selectedFromAccount) return;

    this.totalAmount = this.transfer.wireFee + +this.transfer.amount;

    this.amountExced =
      this.totalAmount > this.transfer.selectedFromAccount.availableBalance;
    this.amountExcedLimit = +this.transfer.amount > this.transferLimit;
  }

  setValidationFromAccount(): void {
    this.selectedFromAccountValid = !(
      this.transfer.selectedFromAccount === undefined
    );
  }

  setValidationPurpose(): void {
    const { purpose = '' } = this.transfer;

    this.purposeValid = this.inputPattern.test(purpose);
    this.purposeBlank = purpose.length === 0;
  }

  goToProfile() {
    this.state.go('udb.userProfile', {
      view: UserProfileViews.PersonalInformation,
    });
  }

  private calculateFeesForAccount(): void {
    this.isLoadingFees = true;
    const cif = this.transfer.selectedFromAccount.isSBB
      ? this.transfer.selectedFromAccount.sbbUserCif
      : this.cookieHelper.getUserCIF();

    this.loadWireTransferFee(cif);
  }

  // Get Domestic & International Wire Transfer Fees by CIF
  private loadWireTransferFee(cif: string): void {
    this.resetRootFeeData();
    this.transactionService
      .getWireTransferFees(cif)
      .then(res => {
        this.root['domesticWireTransferFee'].push(res.data['domesticFee']);
        this.root['internationalWireTransferFee'].push(
          res.data['internationalFee']
        );
        this.parseBalance();
        this.isLoadingFees = false;
        this.isWireFeeCalculated = true;
      })
      .catch(err => {
        console.log(err);
      });
  }

  private setValidations(): void {
    this.setValidationFromAccount();
    this.setValidationAmount();
    this.setValidationPurpose();
  }

  private setWireFee() {
    this.transfer.wireFee =
      this.transfer.destination === this.transferDestiny.us
        ? +this.root['domesticWireTransferFee']
        : +this.root['internationalWireTransferFee'];
  }

  private setWireLimit() {
    this.transferLimit =
      this.transfer.destination === this.transferDestiny.us
        ? +this.root['appSettings'].find(
            (ap: any) => ap.name === AppSettings.WireDomesticLimitAmount
          )?.value
        : +this.root['appSettings'].find(
            (ap: any) => ap.name === AppSettings.WireIntLimitAmount
          )?.value;
  }

  private onStateChangeSuccess() {
    if (this.params['needsReset']) {
      this.state.go('udb.transfers.wireTransfers', { needsReset: false });
    }
  }

  private resetRootFeeData() {
    this.root['domesticWireTransferFee'] = [];
    this.root['internationalWireTransferFee'] = [];
  }

  private defaultWireFeeCheck(): void {
    if (!this.transfer.wireFee && !this.transfer.selectedFromAccount) {
      this.transfer.wireFee = null;
      this.isWireFeeCalculated = false;
    }
  }

  private truncate(number, decimals) {
    const factor = Math.pow(10, decimals);
    return Math.floor(number * factor) / factor;
  }
}
