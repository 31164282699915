import { AggregatedAccount } from 'typings/app/account-aggregation';
import { RewardBalance } from 'typings/app/account-aggregation/RewardBalance';

import { containerType } from '../accounts/container-type.enum';
import { accountType } from '../accounts/account-type.enum';
import { AccountCategory } from '../accounts/account-category.enum';
import { RewardUnits } from '../accounts/rewardUnits.enum';

export class BalanceService {
  rewardUnits = RewardUnits;
  private containerType = containerType;
  private accountType = accountType;
  private accountCategory = AccountCategory;

  getRewardUnits(account: AggregatedAccount): string {
    const reward = this.getReward(account);

    let units = undefined;
    if (reward != undefined) {
      units = reward.units.charAt(0).toUpperCase() + reward.units.slice(1);
    }

    return units;
  }

  getBalance(account: AggregatedAccount): number {
    let balance = 0;

    switch (account.container) {
      case this.containerType.Reward:
        balance = this.getRewardBalance(account);
        break;
      case this.containerType.Bill:
        balance = this.GetBillBalance(account);
        break;
      case this.containerType.Insurance:
        balance = this.GetInsuranceBalance(account);
        break;
      case this.containerType.RealEstate:
        balance = this.GetRealEstateBalance(account);
        break;
      case this.containerType.Bank:
        balance = this.getBankBalance(account);
        break;
      case this.containerType.Investment:
        balance = this.getInvestmentBalance(account);
        break;
      case this.containerType.CreditCard:
        balance = this.GetInvestmentCreditCardBalance(account);
        break;
      case this.containerType.Loan:
        balance = this.GetLoanBalance(account);
        break;
      default:
        // Internal accounts do not have container
        balance = this.GetInternalBalance(account);
        break;
    }

    return balance;
  }

  private getInvestmentBalance(account: AggregatedAccount) {
    return account.isTrading ? account.totalValue ?? 0 : account.balance ?? 0;
  }

  private GetBillBalance(account: AggregatedAccount) {
    const balance = account.balance;

    return balance;
  }

  private GetInvestmentCreditCardBalance(account: AggregatedAccount) {
    const balance = account.balance != null ? account.balance : 0;

    return balance;
  }

  private GetInternalBalance(account: AggregatedAccount) {
    return account.isTrading
      ? account.totalValue ?? 0
      : account.availableBalance ?? 0;
  }

  private GetInsuranceBalance(account: AggregatedAccount) {
    let balance = 0;
    if (account.remainingBalance != null)
      balance = account.remainingBalance.amount;
    else {
      if (
        account.balance != null &&
        (account.accountType !== this.accountType.HomeInsurance ||
          account.accountType !== this.accountType.AutoInsurance)
      ) {
        balance = account.balance;
      }
    }

    return balance;
  }
  private GetLoanBalance(account: AggregatedAccount): number {
    let balance = 0;
    if (account.outstandingBalance != null) {
      balance = account.outstandingBalance.amount;
    } else {
      balance = account.balance != null ? account.balance : 0;
    }

    return balance;
  }

  private GetRealEstateBalance(account: AggregatedAccount): number {
    let homeValue = account.homeValue != null ? account.homevalue.amount : -1;
    if (homeValue === -1)
      homeValue = account.balance != null ? account.balance : 0;
    const balance = homeValue;

    return balance;
  }
  private getReward(account: AggregatedAccount): RewardBalance {
    let reward: RewardBalance;

    reward = account.rewardBalance.find(
      (r: RewardBalance) =>
        r.balanceType === 'BALANCE' || r.description === 'REWARDS'
    );

    if (reward != undefined) {
      reward = account.rewardBalance.find(
        (r: RewardBalance) =>
          r.balanceType === 'TOTAL_BALANCE' || r.description === 'REWARDS'
      );
    }

    return reward;
  }
  private getRewardBalance(account: AggregatedAccount): number {
    let balance = 0;
    let units = this.getRewardUnits(account);
    if (units != undefined) {
      units = units.charAt(0).toLowerCase() + units.slice(1);
    }
    const reward = this.getReward(account);

    if (reward != undefined) {
      if (units === this.rewardUnits.Dollars) balance = reward.balance;
      else {
        // Polyfill for IE does not support Math.trunc
        const truncatePolyfill = (x: number) => {
          if (isNaN(x)) {
            return NaN;
          }
          if (x > 0) {
            return Math.floor(x);
          }

          return Math.ceil(x);
        };
        const truncate = Math.trunc || truncatePolyfill;
        balance = truncate(reward.balance);
      }
    }
    return balance;
  }
  private getBankBalance(account: AggregatedAccount): number {
    let balance = 0;
    const isSavingsOrChecking =
      account.category === this.accountCategory.Sav ||
      account.category === this.accountCategory.Dda;
    const isAvailableBalanceNotNull = account.availableBalance != null;
    if (isSavingsOrChecking && isAvailableBalanceNotNull) {
      balance = account.availableBalance;
    } else {
      balance = account.balance != null ? account.balance : 0;
    }

    return balance;
  }
}
