import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, finalize, switchMap, take } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import idleService, { IdleEvents } from '@kurtz1993/idle-service';
import { TradingAccount } from 'accounts/typings/TradingAccount';
import * as angular from 'angular';
import {
  ILocationService,
  IRootScopeService,
  ISCEService,
  IScope,
  ITimeoutService,
  IWindowService,
} from 'angular';
import { IPromise, IQService } from 'angular';
import { IState, IStateService } from 'angular-ui-router';
import * as jsCookie from 'js-cookie';
import { FacingBrand } from 'models';
import * as moment from 'moment';
import NgRedux from 'ng-redux';
import { BeneficiariesHelper } from 'services/beneficiaries.helper';
import { FeatureFlagService } from 'services/feature-flag.service';
import { IAccountsService } from 'services/typings/IAccountsService';
import { IAxosClearingService } from 'services/typings/IAxosClearingService';
import { IBlastMessagesService } from 'services/typings/IBlastMessagesService';
import { ILoanService } from 'services/typings/ILoanService';
import { ITransactionService } from 'services/typings/ITransactionService';
import { UtagService } from 'services/utag.service';
import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';
import { InternalAccountActions } from 'state-store/actions/accounts/internal.actions';
import { FundingActions } from 'state-store/actions/funding.actions';
import { BlastMessage } from 'typings/app/BlastMessage';
import { UserAction, UserActionField } from 'typings/app/UserAction';
import { environmentNames } from '@app/config/constants';

import {
  loadAggregatedAccounts,
  loadClosedAccounts,
  loadExternalAccounts,
  loadInternalAccounts,
  loadTradingAccounts,
} from '@app/accounts/store/actions';
import { loadAxosAdvisoryAccounts } from '@app/axos-advisory/store/actions';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AxosInvestService } from '@app/axos-invest/services';
import {
  loadGoalSummary,
  loadGoalSummaryWithError,
} from '@app/axos-invest/store/actions';
import {
  setupAccountsToBeFunded,
  setupFundingAccounts,
  turnOffFunding,
  turnOnFunding,
} from '@app/store/funding/funding.actions';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { getUserInfoIsOutdated } from '@app/user-profile/store/selectors';
import { BrandProperty, OlbEvents, UserSubType } from '@core/enums';
import { AxosAdvisoryAccount, FundingAccount, Restriction } from '@core/models';
import {
  AuthService,
  BusinessService,
  DialogService,
  FundingService,
  OlbEventService,
  OutdatedContactInfoService,
  SessionService,
  UserProfileService,
} from '@core/services';
import { AxosAdvisoryService } from '@core/services/axos-advisory.service';
import { IUserProfileService } from '@legacy/services/typings/IUserProfileService';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { SwitchProfileModalComponent } from '@shared/components/modals';
import {
  ClearingAccountType,
  CommunicationIcons,
  FinancialIcons,
  MiscIcons,
  NavigationIcons,
  UtilityIcons,
} from '@shared/enums';
import { CustomerDetail, DialogData, SideMenuItem } from '@shared/models';

import { RestrictionType } from '../common/enums/enums';
import { Inject } from '../decorators/decorators';
import { MessagesSharedService } from '../messages/messages.shared.service';
import { MessagesSharedData } from '../messages/messages.shared.service';
import { BrandPropertyService } from '../services/brand-property.service';
import { ModalService } from '../services/modal.service';
import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { FlowType } from '../typings/app/flow-type.enum';
import { AccountAggregationService } from './../services/account-aggregation.service';
import { CachedAccountsService } from './../services/cached-accounts.service';
import { CachedTradingAccountsService } from './../services/cached-trading-accounts.service';
import { BlastMessageType } from './blast-message/blast-messages-type.enum';
import { ExternalAccountStatus } from './external-account-status.enum';
import * as ContributionActions from '@app/axos-advisory/store/contributions/contributions.actions';

import { PendoTrackerService } from '@app/Areas/AAS/aas-shared/services/pendo-tracker/pendo-tracker.service';
import { NO_CONTRIBUTION_ACCOUNT_TYPES_CONST } from '@legacy/common/constants';
import { RiaFacade } from '@app/Areas/AAS/aas-core/rias';
import { BrandingSettingsFacade } from '@app/Areas/AAS/aas-core/branding-settings';
import { SharedAccountsFacade } from '@app/Areas/AAS/features/account-details/facade/shared-accounts.facade';
import {
  addSharedAccountsPrompting,
  SharedAccount,
  SharedAccountsService,
  SharedAccountsStateType,
} from '@app/Areas/AAS/features/account-details/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ShareAccountRiaComponent } from '@app/accounts/components/modals/share-account-ria/view';
import { ORGANIZATION_NAMES } from '@app/Areas/AAS/features/branding/core/constants';

@Inject(
  '$window',
  '$rootScope',
  '$scope',
  '$state',
  '$q',
  'accountsService',
  'authService',
  'userProfileService',
  'serviceHelper',
  'appSettings',
  '$location',
  '$uibModal',
  'modalService',
  '$sce',
  'transactionService',
  '$timeout',
  'env',
  'messagesSharedService',
  'brandPropertyService',
  'blastMessagesService',
  'cachedAccountsService',
  'accountAggregationService',
  'featureFlagService',
  'utagService',
  '$interval',
  '$ngRedux',
  'cookieHelper',
  'sessionService',
  'loadUserProfileHelper',
  'axosInvestHelper',
  'loanService',
  'axosInvestService',
  'cachedTradingAccountsService',
  'axosClearingService',
  'outdatedContactInfoService',
  'userSubtypeHelper',
  'sitecoreMarketingInterstitialService',
  'sitecoreServicingInterstitialService',
  'olbEventService',
  'beneficiariesHelper',
  'dialogService',
  'newUserProfileService',
  'ngrxStore',
  'fundingService',
  'axosAdvisoryService',
  'supportViewFacade',
  'pendoTracker',
  'brandingSettingsFacade',
  'riaFacade',
  'businessService',
  'sharedAccountsFacade',
  'sharedAccountsService'
)
export class LayoutController {
  get isSiteInReadOnly(): boolean {
    return this.featureFlagService.isSiteInReadOnly();
  }
  memberInfo: CustomerInfo = {};
  isWebBrowser: boolean;
  logOffUrl: string;
  showBanner: boolean;
  alertItems: SideMenuItem[];
  profileItems: SideMenuItem[];
  mainMenuItems: SideMenuItem[];
  isBusy = false;
  hasDepositAccounts = false;
  accounts: AccountsPage;
  tabs: Tab[];
  popoverIsOpen = false;
  adjustView = false;
  maxAttemps = 30;
  brand: string;
  brandName: string;
  messagesSharedData: MessagesSharedData;
  blastMessages: BlastMessage[] = [];
  contactDropdownTpl = '';
  hasOdsAccounts = false;
  isFundingRunning = false;
  showSplashScreen = false;
  isInvestorCheckingPage = false;
  showSplashScreenLastLogin = true;
  showSubmenuLocalAlert: boolean;
  splashScreenWelcomeMessage: string;
  splashScreenLastLogin: string;
  isIraEnhBaseFlagActive: boolean;
  isPfm3Active: boolean;
  isAccountAggregationEnhanceActive: boolean;
  isSBBActive: boolean;
  isLogoutDialogActive = false;

  menuIcons = {
    contact: CommunicationIcons.Phone,
    alerts: UtilityIcons.Bell,
    logout: NavigationIcons.LogOut,
    messages: CommunicationIcons.Envelope,
  };

  isSBBActiveFlag: boolean;
  hasSBBUserManyProfiles: boolean;
  flowTypeEnum = FlowType;
  externalAccountStatus = ExternalAccountStatus;
  isRiaUser: boolean = false;
  isContactButtonEnabled: boolean = false;
  riaAccounts: AxosAdvisoryAccount[];
  noAxosAccountsIsLoading: boolean;
  private phoneNumber: string;
  private tokenRefreshInterval: ng.IPromise<void>;
  private originalTokenExp: Date;
  private subSink = new SubSink();
  private personalInformationModalAlreadyDisplayed = false;
  private dashboadSubscriptionsInitialized = false;

  constructor(
    private windowService: IWindowService,
    private rootScope: IRootScopeService,
    private scope: IScope,
    private state: IStateService,
    private qService: IQService,
    private accountsService: IAccountsService,
    private authService: AuthService,
    private userProfileService: IUserProfileService,
    private serviceHelper: IServiceHelper,
    private appSettings: AppSetting[],
    private location: ILocationService,
    private uibModal: any,
    private modalService: ModalService,
    private sceService: ISCEService,
    private transactionService: ITransactionService,
    private timeoutService: ITimeoutService,
    private env: OlbSettings,
    private messagesSharedService: MessagesSharedService,
    private brandPropertyService: BrandPropertyService,
    private blastMessagesService: IBlastMessagesService,
    private cachedAccountsService: CachedAccountsService,
    private accountAggregationService: AccountAggregationService,
    private featureFlagService: FeatureFlagService,
    private utagService: UtagService,
    private interval: ng.IIntervalService,
    private ngRedux: NgRedux.INgRedux,
    private cookieHelper: CookieHelper,
    private sessionService: SessionService,
    private loadUserProfileHelper: ILoadUserProfileHelper,
    private axosInvestHelper: AxosInvestHelper,
    private loanService: ILoanService,
    private axosInvestService: AxosInvestService,
    private cachedTradingAccountsService: CachedTradingAccountsService,
    private axosClearingService: IAxosClearingService,
    private _outdatedContactInfoService: OutdatedContactInfoService,
    private userSubtypeHelper: UserSubtypeHelper,
    private sitecoreMarketingInterstitialService: ISitecoreMarketingInterstitialService,
    private sitecoreServicingInterstitialService: ISitecoreServicingInterstitialService,
    private olbEventService: OlbEventService,
    private beneficiariesHelper: BeneficiariesHelper,
    private dialogService: DialogService,
    private newUserProfileService: UserProfileService,
    private store: Store,
    private fundingService: FundingService,
    private aasService: AxosAdvisoryService,
    private readonly supportViewFacade: SupportViewFacade,
    private readonly pendoTracker: PendoTrackerService,
    private brandingSettingsFacade: BrandingSettingsFacade,
    private riaFacade: RiaFacade,
    private businessService: BusinessService,
    private sharedAccountsFacade: SharedAccountsFacade,
    private sharedAccountsService: SharedAccountsService,
    public personalizedContentUrl
  ) {
    this.isWebBrowser = !(
      navigator.userAgent.indexOf('iPhone') > -1 ||
      navigator.userAgent.indexOf('Android') > -1
    );
    this.logOffUrl = `/Auth/LogOff/${this.isWebBrowser}`;
    const token = jsCookie.get('XSRF-TOKEN');
    if (!token) {
      this.windowService.location.href = '/';

      return;
    }

    //TODO: Workaround while SSO is ready, once SSO is implemented, delete this
    var userSubType = sessionStorage.getItem('userSubType');
    if (!userSubType) {
      var subType = cookieHelper.getUserSubType();
      sessionStorage.setItem('userSubType', subType ? subType.toString() : '0');
    }

    //TODO: Workaround while SSO is ready, once SSO is implemented, delete this
    var userBrand = sessionStorage.getItem('userbrand');
    if (!userBrand) {
      var brandId = cookieHelper.getUserBrandId();
      sessionStorage.setItem('userbrand', brandId ? brandId.toString() : '0');
      sessionStorage.setItem(
        'userFacingBrand',
        brandId ? brandId.toString() : '0'
      );
    }
  }

  /** Loads layout required data. */
  $onInit(): void {
    this.enableContactButtonIcon();
    this.rootScope['dashboardModalsCanBeDisplayed'] = false;
    this.rootScope['welcomePagePriority'] = true;
    this.isBusy = true;
    this.loadBrandProperties(this.env.brandId, this.brand);
    this.serviceHelper.logWebInformation("User's Layout Initialized");
    this.initialize();
    this.ngRedux.subscribe(this.initialize.bind(this));
    this.rootScope['appSettings'] = [...this.appSettings];
    this.isInvestorCheckingPage =
      this.state.is('udb.InvestorChecking') ||
      this.state.is('udb.interestitialPage');
    this.supportViewFacade.getRIAUserFlag();
    this.setIsRiaUser();
    this.checkSplashScreen();
    this.checkPfm3FeatureFlag();
    this.checkIsSBBActiveFeatureFlag();
    this.setProfileMenuTabsItems();
    this.executeSideEvents();
    this.checkBusinessProfiles();

    this.brand = this.env.brand;
    this.brandName = String(this.env.brandName);
    this.showBanner = this.state.is('udb.dashboard');
    this.adjustDelayedMessage();
    this.setLoanFlag();

    this.loadDashboard();
    this.initEvents();
    this.initUserInactivityEvents();

    this.loadHolidays();

    if (this.featureFlagService.IsRiaPilotAccountSharing()) {
      this.sharedAccountsFacade.getSharedAccounts();
    }

    this.isIraEnhBaseFlagActive = this.featureFlagService.isIraEnhBaseFlagActive();

    this.rootScope.$on('brandPropertiesLoaded', () => {
      const brandProperties = this.rootScope['brandProperties'];
      const investPhoneNumber =
        brandProperties[BrandProperty.ContactPhoneNumberInvest];
      const brandNumber = brandProperties[BrandProperty.ContactPhoneNumber];
      this.phoneNumber =
        investPhoneNumber &&
        this.featureFlagService.isManagedPortfoliosEnabled()
          ? investPhoneNumber
          : brandNumber;
      this.contactDropdownTpl = this.sceService.trustAsHtml(`
        <div class="contact-phone-text contact-label">Phone</div>
        <div class="contact-phone-number">${this.phoneNumber}</div>
      `);

      // Generating Tealium Script
      this.utagService.script_src =
        this.rootScope['brandProperties'].TealiumURL ?? '';
      this.utagService.generateScript('Tealium');

      // Generating Interaction Studio Script
      this.utagService.script_src =
        this.rootScope['brandProperties'].InteractionStudio ?? '';
      this.utagService.generateScript('Interaction Studio');

      this.checkDeepLink();

      const rafUrl = this.rootScope['brandProperties'].ReferAFriendUrl;

      this.addReferAFriendLink(rafUrl);
    });

    this.scope.$on(
      '$stateChangeSuccess',
      (_event, _toState, _toParams, _fromState) => {
        // First time.
        if ((window as any).utag === undefined) {
          this.timeoutService(() => {
            this.recordPageView(this.location.absUrl(), _fromState);
          }, 2000);
        } else {
          this.recordPageView(this.location.absUrl(), _fromState);
        }
      }
    );

    this.initAuthTokenLifeCycle();
    this.serviceHelper.logWebInformation("User's Layout Loaded");

    // ToDo: remove this and change to ng-content to display local alerts
    this.showSubmenuLocalAlert = false;
    this.scope.$on('showSubmenuLocalAlert', (_event: any, show: boolean) => {
      this.showSubmenuLocalAlert = show;
    });

    // SBB Switch Profile
    this.enableSwitchProfileLink();
    this.isBusy = false;

    this.rootScope.$on('dashboardModalsCanBeDisplayed', () => {
      this.loadDashboard();
      this.showOutdatedContactInfoModal(this);
      this.checkFundingAccounts(this);
      if (
        this.rootScope['dashboardModalsCanBeDisplayed'] &&
        !this.rootScope['welcomePagePriority']
      ) {
        this.checkAccountsThatNeedFunding();
        this.getAllSharedAccounts();
      }
    });

    this.rootScope.$on('welcomePagePriority', () => {
      this.loadDashboard();
      this.showOutdatedContactInfoModal(this);
      this.checkFundingAccounts(this);
      if (
        this.rootScope['dashboardModalsCanBeDisplayed'] &&
        !this.rootScope['welcomePagePriority']
      ) {
        this.checkAccountsThatNeedFunding();
      }
    });

    this.rootScope.$on('reloadInternalAccounts', () => {
      this.reloadInternalAccounts();
    });
  }
  checkBusinessProfiles() {
    const USER_BUSINESS = 'userBusinesses';
    var userProfiles = sessionStorage.getItem(USER_BUSINESS);
    if (!userProfiles) {
      this.businessService.getAccountProfiles().subscribe({
        next: result => {
          if (result.data !== null) {
            sessionStorage.setItem(
              USER_BUSINESS,
              result.data ? JSON.stringify(result.data) : ''
            );
          }
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
    }
  }

  checkIsSBBActiveFeatureFlag(): void {
    this.isSBBActive = this.featureFlagService.isSBBActive();
  }

  $onDestroy() {
    this.subSink.unsubscribe();
  }

  /**
   * Moves the delayed message below the secondary navigation if applies
   * this adjustment is applies if detect the .nav-secondary class in the
   * UI-view, and moves it below of the secondary navigation element.
   */
  adjustDelayedMessage(): void {
    this.delayedMessagePosition();
    this.scope.$on('$stateChangeSuccess', () => {
      this.isInvestorCheckingPage =
        this.state.is('udb.InvestorChecking') ||
        this.state.is('udb.interestitialPage');
      this.showBanner = this.state.is('udb.dashboard');
      this.delayedMessagePosition();
    });
    this.scope.$on('$viewContentLoaded', () => {
      this.delayedMessagePosition();
    });
  }

  /**
   * Logs the user out of the application.
   * @param forced Specifies if the system was the one that triggered the logout.
   */
  logout(forced = false): void {
    this.olbEventService.emit(OlbEvents.EndChatbotSession);
    this.sessionService.endSession(forced);
  }

  /** Confirms the user's logout decision */
  confirmLogout(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    const dialogData = new DialogData({
      title: 'Are you sure you want to logout?',
    });

    if (!this.isLogoutDialogActive) {
      this.isLogoutDialogActive = true;
      this.subSink.sink = this.dialogService
        .open(dialogData)
        .afterClosed()
        .subscribe(result => {
          this.isLogoutDialogActive = false;
          return result ? this.logout() : null;
        });
    }
  }

  /** Keeps the user logged in (resets inactivity timer and refreshes token). */
  stayLoggedIn(): void {
    this.originalTokenExp = this.cookieHelper.getTokenExpirationDate();
    this.refreshToken();
    if (
      this.featureFlagService.isAxosInvestActive() &&
      this.axosInvestHelper.hasAxosInvest
    ) {
      this.axosInvestService.getSsoToken();
    }
  }

  /** Closes delayed message */
  closeDelayedMessage(): void {
    this.hasOdsAccounts = false;
    const dm = $('.delayed-accounts');
    const nav = $('.nav--secondary');
    dm.removeClass('delayed-absolute');
    if (nav.length > 0) {
      nav.removeClass('has-message');
    }
  }

  /** redirect to Money pass link */
  openMoneyPassLink(): void {
    let url = '';
    switch (this.env.facingBrandId) {
      case FacingBrand.Axos:
        url = this.rootScope['brandProperties'].AxosMoneyPassUrl;
        break;
      case FacingBrand.Nationwide:
        url = this.rootScope['brandProperties'].NationwideMoneyPassUrl;
        break;
      case FacingBrand.UFB:
        url = this.rootScope['brandProperties'].UfbMoneyPassUrl;
        break;
    }

    this.windowService.open(url, '_blank');
  }

  isTabledScreenSize(): boolean {
    const maxMediaBreakPoint = 768;
    const minMediaBreakPoint = 376;
    const screenSize = this.windowService.window.innerWidth;

    return screenSize >= minMediaBreakPoint && screenSize <= maxMediaBreakPoint;
  }

  redirectToAccAggr(): void {
    this.state.go('udb.accounts.account-aggregation', {
      isAccountAggregationFlow: this.isAccountAggregationEnhanceActive,
    });
  }

  showProfileModal() {
    this.dialogService.openByComponentRef(SwitchProfileModalComponent);
  }

  private showSharedAccountsModalModal() {
    const modalSharedAccounts = this.dialogService.openByComponentRef(
      ShareAccountRiaComponent,
      {
        maxWidth: 1440,
        width: '1440px',
        height: '900px',
        maxHeight: 900,
        disableClose: true,
      }
    ) as MatDialogRef<ShareAccountRiaComponent, any>;

    modalSharedAccounts.componentInstance.closeModal.subscribe(() => {
      modalSharedAccounts.close();
    });
  }

  /** Check if user's token needs to be refreshed based in lifetime/user activity */
  private checkRefreshToken(
    userIdleTime: number = idleService.userState.userInactivityTime
  ) {
    // Check if user is already seeing the auto logout modal (with the countdown)
    const isUserIdling = idleService.userState.isIdle;

    // If user has been idle for more than x seconds... do not refresh token.
    const reasonableIdleTime = 20;
    if (!isUserIdling && !(userIdleTime > reasonableIdleTime)) {
      /**
       * If token has less than half the time remaining to live...
       * then I'm going to consider that the token is not "recent".
       * So... procceed to get a new token with full lifetime
       */
      if (!this.cookieHelper.tokenHasEnoughLifetime()) {
        this.originalTokenExp = this.cookieHelper.getTokenExpirationDate();
        // Refresh token
        this.refreshToken();

        if (
          this.featureFlagService.isAxosInvestActive() &&
          this.axosInvestHelper.hasAxosInvest
        ) {
          // Refresh sso token (Axos Invest)
          this.axosInvestService.getSsoToken();
        }
      }
    }
  }

  /** Initializes refresh token events */
  private initAuthTokenLifeCycle(): void {
    const tokenExp = this.cookieHelper.getTokenExpirationDate();
    this.originalTokenExp = tokenExp;

    const tokenLifetime = this.cookieHelper.getTokenLifetimeInMinutes();
    const refreshIntervalFreq = (tokenLifetime * 60000) / 2;

    this.tokenRefreshInterval = this.interval(() => {
      this.checkRefreshToken();
    }, refreshIntervalFreq);

    this.scope.$on('$destroy', () => {
      this.interval.cancel(this.tokenRefreshInterval);
      this.tokenRefreshInterval = undefined;
    });
  }

  /** When init and every time the state changes this is executed reassigning the funding state */
  private initialize() {
    this.isFundingRunning = this.ngRedux.getState().funding.isRunning;
  }

  private refreshToken() {
    this.authService.refreshToken().subscribe(() => {
      this.scope.$broadcast('refreshToken');
    });
  }

  private setProfileMenuTabsItems(): void {
    this.tabs = [
      {
        id: 'menu-item-dashboard',
        name: 'Dashboard',
        ref: 'udb.dashboard',
        parent: 'udb.dashboard',
        isShown: false,
        showIfRIA: true,
      },
      {
        id: 'menu-item-accounts',
        name: 'Accounts',
        ref: 'udb.accounts.dashboard',
        parent: 'udb.accounts',
        customCss: 'm-accounts',
        isShown: false,
        showIfRIA: true,
      },
      {
        id: 'menu-item-bills',
        name: 'Pay Bills',
        ref: 'udb.billPay.welcome',
        parent: 'udb.billPay',
        isShown: false,
        restriction: RestrictionType.BillPay,
        showIfRIA: false,
      },
      {
        id: 'menu-item-transfers',
        name: 'Move Money',
        ref: 'udb.transfers.transferFunds',
        parent: 'udb.transfers',
        customCss: 'm-transfer',
        isShown: false,
        restriction: RestrictionType.InternalTransfer,
        showIfRIA: true,
      },
      {
        id: 'menu-item-deposit',
        name: 'Make a Deposit',
        ref: 'udb.makedeposit',
        isShown: false,
        restriction: RestrictionType.MobileDeposit,
        showIfRIA: false,
      },
    ];

    this.profileItems = [
      {
        iconName: CommunicationIcons.UserCog,
        label: 'Profile & Settings',
        route: 'udb.userProfile',
      },
      {
        action: this.openMoneyPassLink.bind(this),
        iconName: UtilityIcons.LocationPin,
        label: 'Find an ATM',
      },
      {
        iconName: UtilityIcons.Bell,
        label: 'Alerts',
        route: 'udb.alertsNotifications.myAlerts',
      },
      {
        iconName: CommunicationIcons.Envelope,
        label: 'Messages',
        route: 'udb.messages.inbox',
      },
      {
        iconName: CommunicationIcons.Headset,
        label: 'Support',
        route: 'udb.support',
      },
      {
        action: this.confirmLogout.bind(this),
        iconName: NavigationIcons.LogOut,
        label: 'Log out',
      },
    ];

    this.mainMenuItems = this.isPfm3Active
      ? [
          {
            iconName: UtilityIcons.Tile2,
            label: 'Dashboard',
            route: 'udb.dashboard',
          },
          {
            iconName: FinancialIcons.CoinDollarSign,
            label: 'Accounts',
            route: 'udb.accounts.dashboard',
            subItems: [
              {
                label: 'Overview',
                route: 'udb.accounts.dashboard',
              },
              {
                label: 'Insights',
                route: 'udb.accounts.insights',
              },
              {
                label: 'Add Account',
                action: this.redirectToAccAggr.bind(this),
              },
            ],
          },
          {
            iconName: NavigationIcons.ArrowsHorizontal,
            label: 'Move Money',
            route: 'udb.transfers.transferFunds',
            subItems: [
              {
                label: 'Move Money',
                route: 'udb.transfers.transferFunds',
              },
              {
                label: 'Scheduled Transfers',
                route: 'udb.transfers.schedulerTransfers',
              },
              {
                label: 'Pay It Now',
                route: 'udb.transfers.p2p.sendMoney',
              },
              {
                label: 'Wire Transfers',
                route: 'udb.transfers.wireTransfers',
              },
            ],
          },
          {
            iconName: UtilityIcons.Camera,
            label: 'Make a Deposit',
            route: 'udb.makedeposit',
          },
        ]
      : [
          {
            iconName: UtilityIcons.Tile2,
            label: 'Dashboard',
            route: 'udb.dashboard',
          },
          {
            iconName: FinancialIcons.CoinDollarSign,
            label: 'Accounts',
            route: 'udb.accounts.dashboard',
          },
          {
            iconName: NavigationIcons.ArrowsHorizontal,
            label: 'Move Money',
            route: 'udb.transfers.transferFunds',
            subItems: [
              {
                label: 'Move Money',
                route: 'udb.transfers.transferFunds',
              },
              {
                label: 'Scheduled Transfers',
                route: 'udb.transfers.schedulerTransfers',
              },
              {
                label: 'Pay It Now',
                route: 'udb.transfers.p2p.sendMoney',
              },
              {
                label: 'Wire Transfers',
                route: 'udb.transfers.wireTransfers',
              },
            ],
          },
          {
            iconName: UtilityIcons.Camera,
            label: 'Make a Deposit',
            route: 'udb.makedeposit',
          },
        ];
  }

  private recordPageView(url: string, fromState: ng.ui.IState): void {
    if (!url) return;

    const sendPageData = () => {
      let udbUn = '';
      let udbCifNo = '';
      let udbEm = '';

      const profileInfo = this.rootScope['profileInfo'];
      if (profileInfo) {
        udbUn = profileInfo.username;
        udbCifNo = profileInfo.cif;
        udbEm = profileInfo.primaryEmail;
      }

      const utagData = {
        previous_page_name: fromState.name,
        udb_section: this.location.path(),
        udb_un: udbUn,
        cifno: udbCifNo,
        em: udbEm,
      };

      // Process for Tealium
      this.utagService.view(utagData);
    };

    this.rootScope['profileInfo']
      ? sendPageData()
      : this.rootScope.$on('profileInfoLoaded', () => sendPageData());
  }

  /** Executes sequentially the different side events as promises */
  private executeSideEvents(): void {
    const userMilestonePromise = this.getUserMilestones;
    const accountsWithOutFundsPromise = this.checkFundingAccounts;
    const aggregatedAccountsPromise = this.getAggregatedAccounts;
    const userLiteAccountsPromise = this.getUserLiteAccounts;
    const blastMessagesPromise = this.checkBlastMessages;
    const eligibleForRmdPromise = this.eligibleForRmd;
    const dormantAccountsPromises = this.checkDormantAccounts;
    const userClosedAccountsPromises = this.getUserClosedAccounts;
    const microDepositsPromise = this.checkMicroDeposits;
    const tradingAccountsPromise = this.getTradingAccounts;
    const tradingAcknowledgedPromise = this.tradingAcknowledged;
    const showOutdatedContactInfoModalPromise = this.showOutdatedContactInfoModal.bind(
      this
    );
    const showInterstitialServicingModalPromise = this
      .showInterstitialServicingModal;
    const showMarketingInterstitialModalPromise = this
      .showMarketingInterstitialModal;
    const axosAdvisoryAccountsPromise = this.getAxosAdvisoryAccounts;
    const axosAdvisoryContributionsPromise = this.getAxosAdvisoryContributions;

    this.rootScope['balancesAvailable'] = false;

    const balancesIndependentPromises = new Promise<void>((resolve, reject) => {
      // Get Invest accounts
      Promise.all([
        userMilestonePromise(this).catch(e => e),
        tradingAccountsPromise(this).catch(e => e),
        axosAdvisoryAccountsPromise(this).catch(e => e),
        axosAdvisoryContributionsPromise(this).catch(e => e),
      ])
        .then(() => {
          this.scope.$broadcast('investAccountsLoaded');
          this.rootScope['isInvestAccountsLoaded'] = true;
        })
        .catch(err => this.serviceHelper.serviceError(err));

      userLiteAccountsPromise(this)
        .then(() => {
          this.rootScope.$broadcast('accountsLiteLoaded');
          this.rootScope.$broadcast('accountsLoaded');
        })
        .then(() => {
          return blastMessagesPromise(this);
        })
        .then(() => {
          return userClosedAccountsPromises(this);
        })
        .then(() => resolve())
        .catch(err => {
          this.serviceHelper.errorHandler(err);
          this.serviceHelper.serviceError(err);
          reject();
        });
    });

    const balancesDependentPromises = new Promise<void>((resolve, reject) => {
      this.authService
        .getUserRestrictions()
        .toPromise()
        .then((response: OlbResponse<Restriction[]>) => {
          const restrictionsArray = response.data;
          const restrictions = restrictionsArray.map(d => d.id);
          this.setTabRestriction(restrictions);

          const externalTransferRestriction = 2;
          if (!restrictions.includes(externalTransferRestriction)) {
            return this.accountsService
              .getExternalAccounts()
              .then(result => result.data);
          } else {
            const noExternalAccounts: ExternalAccount[] = [];

            return noExternalAccounts;
          }
        })
        .then((externalAccounts: ExternalAccount[]) => {
          if (this.featureFlagService.IsRiaPilotAccountSharing()) {
            this.sharedAccountsFacade.sharedAccounts$
              .pipe(
                filter(state => !!state),
                take(1)
              )
              .subscribe(state => {
                const mappedExternalAccounts = this.mapSharedAccountsToExternalAccounts(
                  state.sharedAccounts.accounts
                );
                if (externalAccounts) {
                  externalAccounts = [
                    ...externalAccounts,
                    ...mappedExternalAccounts,
                  ];
                }
                this.cachedAccountsService.loadExternalAccounts(
                  externalAccounts
                );
                this.store.dispatch(
                  loadExternalAccounts({ payload: externalAccounts })
                );
              });
          } else {
            this.cachedAccountsService.loadExternalAccounts(externalAccounts);
            this.store.dispatch(
              loadExternalAccounts({ payload: externalAccounts })
            );
          }

          return aggregatedAccountsPromise(this);
        })
        .then(this.getUserAccounts.bind(this))
        .then((accountsWithBalance: AccountsPage) =>
          this.checkDepositIraPrimaryBeneficiary().then(() => {
            return accountsWithBalance;
          })
        )
        .then((accountsWithBalance: AccountsPage) => {
          this.rootScope['balancesAvailable'] = true;

          this.ngRedux.dispatch(InternalAccountActions.accountsBalanceLoaded());
          this.rootScope.$broadcast('balancesAvailable');
          this.olbEventService.emit(OlbEvents.BalancesAvailable);

          // prevent unnecessary call if already was done
          if (
            sessionStorage.getItem('fundRemembered') !== 'true' ||
            sessionStorage.getItem('signatureCardChecked') !== 'true' ||
            sessionStorage.getItem('microDepositChecked') !== 'true'
          ) {
            microDepositsPromise(this)
              .then(() =>
                this.accountsService.getSortedAccountsIntercepts(
                  accountsWithBalance
                )
              )
              .then((resultFromInterceptors: OlbResponse<AccountsPage>) => {
                const accountsFromInterceptors = resultFromInterceptors.data;
                this.accounts = accountsFromInterceptors;

                // refresh accounts stored
                this.cachedAccountsService.loadInternalAccounts(
                  accountsFromInterceptors
                );
                const accounts = JSON.parse(
                  JSON.stringify(accountsFromInterceptors)
                ) as AccountsPage;
                this.store.dispatch(
                  loadInternalAccounts({ payload: accounts })
                );
              })
              .then(() => dormantAccountsPromises(this))
              .then(() => {
                this.messagesSharedService.callUnreadMessagesCount();
                this.messagesSharedData = this.messagesSharedService.getUnreadMessagesCount();
                resolve();
              });
          }
        })
        .catch(err => {
          this.serviceHelper.errorHandler(err);
          reject();
        });
    });

    Promise.all([balancesIndependentPromises, balancesDependentPromises])
      .then(() => {
        this.loadUserProfileHelper
          .getUserProfilePromise()
          .then(() => tradingAcknowledgedPromise(this))
          .then(() => accountsWithOutFundsPromise(this))
          .then(() => {
            if (
              this.rootScope['dashboardModalsCanBeDisplayed'] &&
              !this.rootScope['welcomePagePriority']
            ) {
              this.checkAccountsThatNeedFunding();
            }
          })
          .then(() => eligibleForRmdPromise(this))
          .then(() => showOutdatedContactInfoModalPromise(this))
          .then(() => showInterstitialServicingModalPromise(this))
          .then(() => showMarketingInterstitialModalPromise(this));
      })
      .catch(this.serviceHelper.errorHandler);
  }

  /** Gets the user accounts (sorted) */
  private getUserAccounts(): IPromise<any> {
    const deferred = this.qService.defer();
    const promise = deferred.promise;
    this.rootScope['userAccountsPromise'] = deferred;

    this.accountsService
      .getSortedAccountsBalance()
      .then(res => {
        if (res.data) {
          this.accounts = res.data;

          // Store a subset of the response into a separate variable which will be used by the
          //   primary-beneficiary-missing intercept logic, this is needed since the variable
          //   this.accounts can be overwritten with the result of liteAccounts or accountsInterceptor
          //   depending on which one completed first and the taxPlanType is only filled here
          this['accountsForDiraIntercept'] = res.data.depositAccounts.filter(
            a =>
              a.taxPlanType &&
              this.beneficiariesHelper.isAddBeneficiariesActive(a)
          );

          // once all accounts have their balances, call 'loadInternalAccounts' funtion to store accounts
          this.cachedAccountsService.loadInternalAccounts(res.data);
          const accounts = JSON.parse(JSON.stringify(res.data)) as AccountsPage;
          this.store.dispatch(loadInternalAccounts({ payload: accounts }));

          // Hack to add active class to menus if any sub-state is selected
          this.setActiveMenuItem();

          deferred.resolve(res.data);
        }
      })
      .catch(error => {
        deferred.reject(error);
      });

    return promise;
  }

  /** Gets the user accounts for menu (sorted) */
  private getUserLiteAccounts(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['userMenuAccountsPromise'] = deferred;

    self.accountsService
      .getSortedLiteAccounts()
      .then(res => {
        // If 'accounts/sorted' already returned, prevent overwrite self.accounts
        if (self.rootScope['balancesAvailable']) {
          deferred.resolve(true);

          return;
        }
        if (res.data) {
          self.accounts = res.data;
          self.checkIfHasDepositAccounts();
          // add accounts without balances
          self.cachedAccountsService.loadInternalAccounts(res.data);
          const accounts = JSON.parse(JSON.stringify(res.data)) as AccountsPage;
          self.store.dispatch(loadInternalAccounts({ payload: accounts }));
          // Hack to add active class to menus if any sub-state is selected
          self.setActiveMenuItem();

          deferred.resolve(true);
        }
      })
      .catch(error => {
        deferred.reject(error);
      });

    return promise;
  }

  /** Gets the user Milestones for Axos Invest */
  private getUserMilestones(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['userMilestonePromise'] = deferred;

    if (
      self.featureFlagService.isAxosInvestActive() &&
      self.axosInvestHelper.hasAxosInvest
    ) {
      self.axosInvestService.getMilestones().subscribe(
        res => {
          self.store.dispatch(loadGoalSummary({ payload: res.data }));
          self.rootScope['milestonesDetail'] = res.data;
          self.axosInvestHelper.addInvestAccount(
            res.data.milestones?.filter(x => x.isClosed === false).length,
            res.data.totalBalance,
            res.data.hasError,
            res.data.isClosed
          );
          deferred.resolve(res.data);
        },
        error => {
          self.store.dispatch(loadGoalSummaryWithError());
          deferred.reject(error);
        }
      );
    } else {
      deferred.resolve(true);
    }

    return promise;
  }

  private checkAccountsThatNeedFunding(): IPromise<any> {
    const deferred = this.qService.defer();
    const promise = deferred.promise;
    this.rootScope['fundingPromise'] = deferred;

    // Check firstly if funding is already running (when comes from enrollment)
    if (this.isFundingRunning) {
      return promise;
    }

    if (!sessionStorage.getItem('fundingRemembered')) {
      this.validateFundingAccounts();
      this.validateRIAFundingAccounts();
    } else {
      deferred.resolve();
    }

    return promise;
  }

  private getAllSharedAccounts() {
    if (this.featureFlagService.IsRiaPilotAccountSharing()) {
      if (
        sessionStorage.getItem('sharedAccountsModal') == undefined ||
        !sessionStorage.getItem('sharedAccountsModal')
      ) {
        sessionStorage.setItem('sharedAccountsModal', 'true');
        this.sharedAccountsService
          .getSharedAccountsPrompting()
          .subscribe(response => {
            if (response.data.accounts.length !== 0) {
              const sharedAccountsStateTypeState: SharedAccountsStateType = {
                sharedAccounts: response.data,
              };
              this.store.dispatch(
                addSharedAccountsPrompting({
                  payload: sharedAccountsStateTypeState,
                })
              );
              this.showSharedAccountsModalModal();
            }
          });
      }
    }
  }

  private mapSharedAccountsToExternalAccounts(
    sharedAccounts: Array<SharedAccount>
  ) {
    let sharedAccountToDisplay: Array<ExternalAccount> = [];
    sharedAccounts.forEach(sharedAccount => {
      const hasAuthorizeTransfer = sharedAccount?.advisors?.find(
        advisor => advisor.authorizedTransfer
      );
      const itsOnExternalAccounts = this.cachedAccountsService?.externalAccounts?.find(
        externalAcc => externalAcc.accountNumber === sharedAccount.accountId
      );
      const itsOnAggregatedAccounts = this.cachedAccountsService?.aggregatedAccounts?.find(
        AggregatedAcc => AggregatedAcc.accountNumber === sharedAccount.accountId
      );
      if (
        hasAuthorizeTransfer &&
        !itsOnExternalAccounts &&
        !itsOnAggregatedAccounts
      )
        sharedAccountToDisplay.push({
          type: sharedAccount.accountType,
          bankName: sharedAccount.institutionName,
          externalAccountId: Number(sharedAccount.accountId),
          status: 'Active',
          nickname: sharedAccount.accountNickname,
          accountMask: sharedAccount.accountMask,
          displayName: `${sharedAccount.institutionName} - ${sharedAccount.accountNickname}`,
          aggAccountId: Number(sharedAccount.accountId),
          accountNumber: sharedAccount.accountId,
          isPendingVerification: false,
          isTransferOnly: true,
          isSBB: false,
          isAuthorizeTransfer: true,
        });
    });

    return sharedAccountToDisplay;
    // if (sharedAccountToDisplay.length > 0) {
    //   this.cachedAccountsService.addExternalAccounts(sharedAccountToDisplay);
    //   this.store.dispatch(
    //     loadExternalAccounts({ payload: sharedAccountToDisplay })
    //   );
    // }
  }

  private getAxosAdvisoryAccounts(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;

    const userSubType = parseInt(sessionStorage.getItem('userSubType'));
    const hasAxosAdvisoryService =
      (userSubType & UserSubType.AxosAdvisoryService) > 0;
    self.rootScope['hasAxosAdvisory'] = hasAxosAdvisoryService;

    if (
      self.featureFlagService.isRiaPilotActive() &&
      hasAxosAdvisoryService &&
      self.env.facingBrandId === FacingBrand.Axos
    ) {
      if (self.featureFlagService.isRiaPilotAllowedAccountsActive()) {
        self.aasService
          .getAxosAdvisoryAccountsWithRias()
          .subscribe(axosAdvisoryAccountsResponse => {
            const { accounts, rias } = axosAdvisoryAccountsResponse.data;
            self.store.dispatch(
              loadAxosAdvisoryAccounts({ payload: accounts })
            );
            //Adding "axos" to the organizationNames collection to retrieve and use its branding settings
            //when a particular organization has not opted to use custom branding settings
            let organizationNames = [
              ...rias.map(ria => ria.name),
              ORGANIZATION_NAMES.Axos,
            ];
            self.brandingSettingsFacade.loadBrandsSettings(organizationNames);
            self.riaFacade.loadRias(rias);
            deferred.resolve(axosAdvisoryAccountsResponse.data);
          });
      } else {
        self.aasService
          .getAxosAdvisoryAccounts()
          .subscribe(advisoryAccountsResponse => {
            self.store.dispatch(
              loadAxosAdvisoryAccounts({
                payload: advisoryAccountsResponse.data,
              })
            );
            deferred.resolve(advisoryAccountsResponse.data);
          });
      }
    } else {
      deferred.resolve(true);
    }

    return promise;
  }
  /**
   * Get total contributions for Invest and RIA Accounts (waiting for Axos accounts).
   * Moving this service call to layout controller, because service takes time to load contributtions from Orbis and Liverty
   * @param self
   * @returns
   */
  private getAxosAdvisoryContributions(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;

    const featureFlagRiaPilot = self.featureFlagService.isRiaPilotActive();
    const userSubType = parseInt(sessionStorage.getItem('userSubType'));
    const hasAxosAdvisoryService =
      (userSubType & UserSubType.AxosAdvisoryService) > 0;

    if (
      featureFlagRiaPilot &&
      hasAxosAdvisoryService &&
      self.env.facingBrandId === FacingBrand.Axos
    ) {
      self.aasService.getAxosAdvisoryContributions().subscribe(res => {
        self.store.dispatch(
          ContributionActions.loadContributionsSuccess({
            payload: res.data,
          })
        );
        deferred.resolve(res.data);
      });
    } else {
      deferred.resolve(true);
    }

    return promise;
  }

  private validateFundingAccounts() {
    const deferred = this.rootScope['fundingPromise'] as angular.IDeferred<
      unknown
    >;
    this.fundingService
      .getAccounts()
      .toPromise()
      .then(response => {
        const accounts = response.data;
        if (
          accounts.length > 0 &&
          this.rootScope['dashboardModalsCanBeDisplayed'] &&
          !this.rootScope['welcomePagePriority']
        ) {
          this.showFundingPopup(accounts);
          sessionStorage.setItem('fundingRemembered', 'true');
        } else {
          deferred.resolve();
        }
      })
      .catch(() => {
        deferred.resolve();
      });
  }

  private showFundingPopup(accounts: FundingAccount[]) {
    const deferred = this.rootScope['fundingPromise'] as angular.IDeferred<
      unknown
    >;
    const message = `Your account <b>${accounts[0].name}</b> has no funds`;

    this.modalService
      .show(
        { keyboard: false },
        {
          hasIcon: true,
          icon: 'bofi-warning',
          hasHeaderText: false,
          okText: 'Fund My Account',
          cancelText: 'Remind Me Later',
          hasClose: true,
          hasCancelButton: true,
          bodyText: `<h3>Funding Reminder</h3>
          <div class='funding__body'>${message}</div>`,
          okButtonClass: 'uk-btn solid secondary lg funding__buttons-button',
          cancelButtonClass:
            'uk-btn outline primary lg funding__buttons-button',
          containerButtonsClass: 'funding__buttons',
        }
      )
      .then(() => {
        this.ngRedux.dispatch(FundingActions.turnOnFunding());
        this.store.dispatch(turnOnFunding());
        this.ngRedux.dispatch(FundingActions.setupFundingAccounts(accounts));
        this.store.dispatch(setupFundingAccounts({ payload: accounts }));
        sessionStorage.setItem('fundingAccounts', JSON.stringify(accounts));
        this.state.go('udb.managedportfolio'); // TODO: Implement other routes
      })
      .catch(() => {
        deferred.resolve();
      });
  }

  private validateRIAFundingAccounts() {
    const deferred = this.rootScope['fundingPromise'] as angular.IDeferred<
      unknown
    >;
    if (this.featureFlagService.isRiaPilotActive()) {
      this.subSink.sink = this.store
        .select(getAxosAdvisoryAccounts)
        .subscribe(riaAccounts => {
          this.riaAccounts = riaAccounts;
          this.isContactButtonEnabled = !this.isRiaUser;
        });
    }

    this.isContactButtonEnabled = !this.isRiaUser;

    if (this.isRiaUser) {
      const fundingAccounts: FundingAccount[] = new Array();

      this.riaAccounts.forEach(riaAccount => {
        if (riaAccount.accountBalance === 0) {
          sessionStorage.setItem('fundingRemembered', 'true');
          const riaFundingAcc: FundingAccount = {
            id: riaAccount.riaId,
            name: `${riaAccount.type} - ${riaAccount.accountNumber.substring(
              riaAccount.accountNumber.length - 4,
              riaAccount.accountNumber.length
            )}`,
            userSubType: UserSubType.AxosAdvisoryService,
          };
          if (
            !NO_CONTRIBUTION_ACCOUNT_TYPES_CONST.includes(
              riaAccount.accountTypeCode
            )
          ) {
            fundingAccounts.push(riaFundingAcc);
          }
        }
      });

      if (
        fundingAccounts.length > 0 &&
        this.rootScope['dashboardModalsCanBeDisplayed'] &&
        !this.rootScope['welcomePagePriority']
      ) {
        this.showRIAFundingPopup(fundingAccounts);
      } else {
        deferred.resolve();
      }
    }
  }

  private showRIAFundingPopup(accounts: FundingAccount[]) {
    const deferred = this.rootScope['fundingPromise'] as angular.IDeferred<
      unknown
    >;
    const message = `Your newly opened account <b>${accounts[0].name}</b> has not been funded. Please fund your account.`;

    this.modalService
      .show(
        { keyboard: false },
        {
          hasIcon: true,
          icon: 'bofi-warning',
          hasHeaderText: false,
          okText: 'Fund My Account',
          cancelText: 'Remind Me Later',
          hasClose: true,
          hasCancelButton: true,
          bodyText: `<h3>Funding Reminder</h3>
          <div class='funding__body'>${message}</div>`,
          okButtonClass: 'uk-btn solid secondary lg funding__buttons-button',
          cancelButtonClass:
            'uk-btn outline primary lg funding__buttons-button',
          containerButtonsClass: 'funding__buttons',
        }
      )
      .then(() => {
        this.ngRedux.dispatch(FundingActions.turnOnFunding());
        this.store.dispatch(turnOnFunding());
        this.ngRedux.dispatch(FundingActions.setupFundingAccounts(accounts));
        this.store.dispatch(setupFundingAccounts({ payload: accounts }));
        sessionStorage.setItem('fundingAccounts', JSON.stringify(accounts));
        this.state.go('udb.transfers.transferFunds'); // TODO: Implement other routes
      })
      .catch(() => {
        deferred.resolve();
      });
  }

  /** Gets the user Trading accounts */
  private async getTradingAccounts(self: LayoutController) {
    const deferred = self.qService.defer();
    const promise = deferred.promise;

    if (self.axosClearingService.isAxosTradingActiveForUser()) {
      await self.axosClearingService
        .getAccounts()
        .then((res: OlbResponse<TradingAccount[]>) => {
          self.store.dispatch(
            loadTradingAccounts({
              payload: JSON.parse(JSON.stringify(res.data || [])),
            })
          );
          self.cachedTradingAccountsService.loadTradingAccounts(res.data || []);
          deferred.resolve(true);
        })
        .catch(error => {
          deferred.reject(error);
        });
    } else {
      deferred.resolve(true);
    }

    return promise;
  }

  private getUserClosedAccounts(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;

    self.accountsService
      .getClosedAccounts()
      .then(({ data }) => {
        self.store.dispatch(
          loadClosedAccounts({ payload: JSON.parse(JSON.stringify(data)) })
        );
        self.rootScope['closedAccounts'] = data;
        self.rootScope.$broadcast('closedAccountsLoaded');
        deferred.resolve(true);
      })
      .catch(error => deferred.reject(error));

    return promise;
  }

  /** Creates a Promise for Account Funding process */
  private checkFundingAccounts(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['accountsWithOutFundsPromise'] = deferred;

    // If funding is inactive return the promise resolved
    if (!self.featureFlagService.isFundingActive()) {
      deferred.resolve(true);

      return promise;
    }

    // Check firstly if funding is already running (when comes from enrollment)
    if (self.isFundingRunning) {
      return promise;
    }

    if (
      sessionStorage.getItem('fundRemembered') &&
      sessionStorage.getItem('fundRemembered') !== 'false'
    ) {
      deferred.resolve(true);
    } else {
      try {
        self.verifyAccountsWithoutFunds([
          ...self.accounts.depositAccounts,
          ...self.accounts.loanAccounts,
          ...self.cachedTradingAccountsService.tradingAccounts.map(t => {
            t.nickname = `${t.nickname} **${t.accountNumber.substr(
              t.accountNumber.length - 4
            )}`;

            return t;
          }),
        ]);
      } catch (error) {
        self.serviceHelper.errorHandler(error);
        deferred.resolve(true);
      }
    }

    return promise;
  }

  private checkMicroDeposits(self: LayoutController): IPromise<boolean> {
    const deferred = self.qService.defer<boolean>();
    const promise = deferred.promise;
    self.rootScope['microDepositPromise'] = deferred;

    const promiseResponse = true;

    const externalAccounts = self.cachedAccountsService.externalAccounts || [];

    const filteredAccounts = externalAccounts.filter(
      ac =>
        ac.status === self.externalAccountStatus.InProcess ||
        ac.status === self.externalAccountStatus.PendingActivation
    );

    const microDepositChecked =
      sessionStorage.getItem('microDepositChecked') === 'true';

    self.setMicroDepositFlag();

    if (microDepositChecked || !filteredAccounts.length) {
      // continue with funding flow
      deferred.resolve(promiseResponse);

      return promise;
    }

    const externalAccount = filteredAccounts
      .sort((first: ExternalAccount, second: ExternalAccount) =>
        first.displayName.toLowerCase() > second.displayName.toLowerCase()
          ? 1
          : -1
      )
      .find(a => a.status === self.externalAccountStatus.PendingActivation);

    // do not display micro deposit modal nor funding modal
    if (!externalAccount) {
      // funding flag marked with true, to skip funding flow
      self.setFundFlag();
      deferred.resolve(promiseResponse);

      return promise;
    }

    // display micro deposit modal
    sessionStorage.setItem(
      'externalAccountInProcess',
      JSON.stringify(externalAccount)
    );

    self.showMicroDepositModal(externalAccount, promiseResponse);

    return promise;
  }

  // Creates a Promise for get aggregation accounts
  private getAggregatedAccounts(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;

    if (!self.featureFlagService.isAccountAggregationActive()) {
      self.cachedAccountsService.loadAggregatedAccounts([]);
      self.store.dispatch(loadAggregatedAccounts({ payload: [] }));
      deferred.resolve(true);

      return promise;
    }

    self.accountAggregationService
      .getAccounts()
      .then(res => {
        self.cachedAccountsService.loadAggregatedAccounts(res.data);
        self.store.dispatch(
          loadAggregatedAccounts({
            payload: JSON.parse(JSON.stringify(res.data)),
          })
        );

        deferred.resolve(true);
      })
      .catch(error => {
        if (error.status === 404) {
          self.cachedAccountsService.loadAggregatedAccounts([]);
          self.store.dispatch(loadAggregatedAccounts({ payload: [] }));
          deferred.resolve(true);

          return;
        }
        self.cachedAccountsService.loadAggregatedAccounts(new Error());
        deferred.reject(error);
      });

    return promise;
  }

  private checkDepositIraPrimaryBeneficiary(): IPromise<any> {
    // - Tax plan types (like IRA or Roth) are unique within a customer
    // - Beneficiaries are stored within a tax plan
    // - This means that if the user has multiple ROTH accounts, they all point to the same
    //     tax plan (type ROTH) and they all share the same beneficiaries
    // - There could be users that have IRA and Roth accounts, and the respective IRA and Roth tax plans
    //     don't have primary beneficiaries setup, for this case, show one intercept per taxPlanType per login
    //     so in the first login show the IRA intercept and in the next login show the Roth intercept (order isn't relevant)

    if (!this.featureFlagService.isIraEnhDiraFlagActive())
      return this.qService.resolve();

    const deferred = this.qService.defer();

    const iraAccountsByTaxPlanType = this['accountsForDiraIntercept'].reduce(
      (carry: any, account) => {
        // groupBy taxPlanType

        if (!carry.hasOwnProperty(account.taxPlanType))
          carry[account.taxPlanType] = [];
        carry[account.taxPlanType].push(account);

        return carry;
      },
      {}
    );

    const taxPlans = Object.keys(iraAccountsByTaxPlanType).map(
      key => iraAccountsByTaxPlanType[key][0] as OlbAccount
    ); // pick first account of a taxPlanType group (order isn't relevant)

    const tradingIra = this.cachedTradingAccountsService.tradingAccounts.filter(
      ta =>
        (ta.type === ClearingAccountType.IraTraditional ||
          ta.type === ClearingAccountType.IraRoth) &&
        ta.beneficiaries?.length === 0 &&
        ta?.statusName?.toLowerCase() === 'active'
    );
    tradingIra.forEach(ta => {
      taxPlans.push(ta as OlbAccount);
    });

    if (taxPlans.length === 0) deferred.resolve();

    const cif = this.cookieHelper.getUserCIF();

    this.qService
      .all(
        taxPlans.map(taxPlan =>
          this.accountsService.shouldShowDiraPrimaryBeneficiary(
            cif,
            taxPlan.taxPlanType
          )
        )
      )
      .then(responses => {
        const firstInterceptIndex = responses.findIndex(resp => resp.data); // Pick first tax plan that is missing primary benef. (order isn't relevant)
        if (firstInterceptIndex === -1) {
          deferred.resolve();

          return;
        } // All tax plans have primary benef. exit

        this.showBeneficiariesModal(
          deferred,
          taxPlans[firstInterceptIndex].id,
          cif,
          taxPlans[firstInterceptIndex].taxPlanType
        );
      })
      .catch(e => {
        this.serviceHelper.errorHandler(e);
        deferred.resolve();
      });

    return deferred.promise;
  }

  // Creates a Promise for Blast Messages
  private checkBlastMessages(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['blastMessagesPromise'] = deferred;

    try {
      const activeBlastMessage = self.rootScope['appSettings'].find(
        (ap: any) => ap.name === 'ActiveBlastMessages'
      ).value;
      const blastMessagesShown =
        sessionStorage.getItem('blastMessagesShown') || 'false';

      if (activeBlastMessage === 'true' && blastMessagesShown === 'false') {
        self.showBlastMessages();
      } else {
        deferred.resolve(true);
      }
    } catch (error) {
      self.serviceHelper.errorHandler(error);
      deferred.resolve(true);
    }

    return promise;
  }

  /**
   * Performs the proper validations is the user is applicable for Required Minimum Distribution
   * @param self Layout controller reference
   */
  private eligibleForRmd(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['eligibleForRmdPromise'] = deferred;

    try {
      const { isEligibleForRmd, dateOfBirth } = self.rootScope['profileInfo'];
      const { depositAccounts } = self.cachedAccountsService.internalAccounts;
      const yearsOld = moment('12/31', 'MM/DD').diff(
        moment(dateOfBirth),
        'years',
        true
      );
      const iraAccounts = depositAccounts.filter(
        acc => acc.isIra && acc.canBeFromAccount
      );
      const dateOfMandatoryDistribution = new Date('1949-06-30');

      if (
        !isEligibleForRmd &&
        iraAccounts.length &&
        (new Date(Date.parse(dateOfBirth)) <= dateOfMandatoryDistribution ||
          yearsOld > 72)
      ) {
        self.userProfileService
          .updateEligibleForRmd(true)
          .then(() => {
            self.rootScope['profileInfo'].isEligibleForRmd = true;

            self.modalService
              .show(
                {},
                {
                  hasIcon: true,
                  icon: 'bofi-warning',
                  hasHeaderText: false,
                  okText: 'Schedule a Distribution',
                  hasClose: false,
                  hasCancelButton: false,
                  bodyText: `<h3>Required Minimum Distribution (RMD)</h3>
                    <p class='rdm__body'>
                        You may be required to take a distribution from your IRA account this year.
                    </p>`,
                  okButtonClass:
                    'uk-btn solid secondary lg other-brand-redirect__button',
                }
              )
              .then(() => {
                self.state.go('udb.transfers.transferFunds', {
                  fromAccountId: iraAccounts[0].id,
                });
              });
          })
          .catch(self.serviceHelper.errorHandler);
      }

      deferred.resolve(true);
    } catch (error) {
      self.serviceHelper.errorHandler(error);
      deferred.resolve(true);
    }

    return promise;
  }

  // Creates a Promise for Dormant Accounts
  private checkDormantAccounts(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['dormantAccountsPromise'] = deferred;

    try {
      const activeEnableDormant = self.appSettings.find(
        appSetting => appSetting.name === 'ActiveEnableDormant'
      ).value;
      const dormantAccountsShown =
        sessionStorage.getItem('dormantAccountsShown') || false;

      if (activeEnableDormant === 'true' && !dormantAccountsShown) {
        self.verifyEnableDormantAccounts();
      } else {
        deferred.resolve(true);
      }
    } catch (error) {
      self.serviceHelper.errorHandler.bind(self);
      deferred.resolve(true);
    }

    return promise;
  }

  /**
   * Show trading acknowledged disclaimer popup
   * @param self Layout controller reference
   */
  private tradingAcknowledged(self: LayoutController): IPromise<any> {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['tradingAcknowledgedPromise'] = deferred;

    const userSubType = +sessionStorage.getItem('userSubType') ?? 0;
    if ((userSubType & UserSubType.AxosInvest) !== UserSubType.AxosInvest) {
      deferred.resolve(true);
      return promise;
    }

    const hasInvestAccounts = self.axosInvestService
      .getMilestones()
      .subscribe(res => {
        return res.data.accounts?.length;
      });
    try {
      const { axosTradingAcknowledged } = self.rootScope['profileInfo'];
      if (
        (self.axosClearingService.isAxosTradingActiveForUser() &&
          !axosTradingAcknowledged &&
          self.cachedTradingAccountsService.tradingAccounts.length) ||
        (self.featureFlagService.isAxosInvestActive() &&
          self.axosInvestHelper.hasAxosInvest &&
          !axosTradingAcknowledged &&
          hasInvestAccounts)
      ) {
        self.modalService
          .show(
            { keyboard: false },
            {
              hasIcon: true,
              icon: 'bofi-warning',
              hasHeaderText: false,
              okText: 'I Understand',
              hasClose: false,
              hasCancelButton: false,
              bodyText: `<h3>Brokerage Accounts by Axos Invest</h3>
                <p class='rdm__body'>
                  Please keep in mind that Self-Directed Trading, Managed Portfolios,
                  and other non-deposit securities and investment products and
                  services are not insured by the FDIC. They are not deposits, obligations
                  of, or guaranteed by Axos Bank, N.A., and are subject to investment risk,
                  including possible loss of the principal amount invested.
                </p>`,
              okButtonClass:
                'uk-btn solid secondary lg other-brand-redirect__button',
            }
          )
          .then(() => {
            self.userProfileService.updateTradingAcknowledged();
            deferred.resolve(true);
          });
      } else {
        deferred.resolve(true);
      }
    } catch (error) {
      self.serviceHelper.errorHandler(error);
      deferred.resolve(true);
    }

    return promise;
  }

  /** Verifies if the user has deposit accounts */
  private checkIfHasDepositAccounts(): void {
    if (this.accounts.depositAccounts.length > 0) {
      this.hasDepositAccounts = true;
    }
  }

  /** Loads layout and dashboard data. */
  private loadDashboard(): void {
    if (
      (!this.rootScope['dashboardModalsCanBeDisplayed'] ||
        this.rootScope['welcomePagePriority']) &&
      this.dashboadSubscriptionsInitialized
    )
      return;

    this.subSink.sink = this.supportViewFacade.isLoading$
      .pipe(filter(isLoading => !isLoading))
      .subscribe(() => {
        this.getUserProfileDetails();
      });

    this.dashboadSubscriptionsInitialized = true;
  }

  /**
   * Get User Profile Details and initialize Pendo
   */
  private getUserProfileDetails(): void {
    this.newUserProfileService.getUserProfileDetails().subscribe({
      next: res => {
        this.rootScope['profileInfo'] = angular.copy(res.data);
        this.populateUserInfo(res.data);
        this.rootScope.$broadcast('profileInfoLoaded', res.data);

        const { cif, username } = res.data;
        // Pendo POC code
        const visitorId = !(this.env.environment == environmentNames.Production)
          ? `${this.cookieHelper.getUserId()}_${this.env.environment}`
          : this.cookieHelper.getUserId();

        this.scope.$on('accountsLoaded', () => {
          const isSbbUser = this.cachedAccountsService.paymentAccounts?.some(
            acc => acc.isSBB
          );
          this.pendoTracker.initializePendo({
            visitor: {
              id: visitorId,
              username,
              cif,
              riaUser: this.isRiaUser,
              sbbUser: isSbbUser,
            },
            account: {
              id: this.env.facingBrandId,
              FacingBrand: this.env.brandName,
            },
          });
        });
      },
      error: error => {
        // after a new errollment profileInfo is not available until a few minutes
        if (error.status === 404) {
          setTimeout(() => this.loadDashboard(), 30000);
        }
      },
    });
  }

  /** Initializes any required events. */
  private initEvents(): void {
    window.onbeforeunload = () => {
      localStorage.setItem('lastActivity', new Date().toString());
    };

    this.rootScope.$on(
      '$stateChangeSuccess',
      (_event, toState: IState, _toParams, fromState: IState) => {
        this.showBanner = this.state.is('udb.dashboard');
        // Hack to add active class to menus if any sub-state is selected
        this.delayedMessagePosition();
        this.setActiveMenuItem();

        if (
          toState.name === 'udb.dashboard' &&
          fromState.name.indexOf('udb.funding') >= 0 &&
          this.ngRedux.getState().funding.isRunning
        ) {
          this.ngRedux.dispatch(FundingActions.turnOffFunding());
          this.store.dispatch(turnOffFunding());
          sessionStorage.removeItem('accountsToBeFunded');
          const fundingPromise = this.rootScope['accountsWithOutFundsPromise'];
          fundingPromise.resolve();
        } else if (
          toState.name === 'udb.dashboard' &&
          fromState.name.indexOf('udb.managedportfolio') >= 0
        ) {
          this.ngRedux.dispatch(FundingActions.turnOffFunding());
          this.store.dispatch(turnOffFunding());
          sessionStorage.removeItem('fundingAccounts');
          const deferred = this.rootScope[
            'fundingPromise'
          ] as angular.IDeferred<unknown>;
          if (deferred) deferred.resolve();
        }
      }
    );
  }

  /** Initializes events regarding user session idling and timeout. */
  private initUserInactivityEvents(): void {
    // Configure
    const idleTime = +this.appSettings.find(setting => {
      return setting.name === 'MaxInactiveTime';
    }).value;
    const timeoutTime = +this.appSettings.find(setting => {
      return setting.name === 'TimeoutPopup';
    }).value;

    idleService.configure({
      timeToIdle: idleTime,
      timeToTimeout: timeoutTime,
      listenFor: 'click keypress touchstart',
    });

    idleService.start();

    idleService.on(IdleEvents.UserIsIdle, () => {
      // If token has less than half the total lifetime remaining, I'm assuming the token has not been recently refreshed
      if (
        !this.cookieHelper.tokenRefreshedRecently(this.originalTokenExp) ||
        !this.cookieHelper.tokenHasEnoughLifetime()
      ) {
        this.showUserInactivityModal();
      }
    });

    idleService.on(IdleEvents.UserIsActive, () => {
      this.checkRefreshToken(idleService.userState.userInactivityTime);
    });
  }

  /**
   * Populates the user information.
   * Also gets the user's set dashboard view.
   * @param userInfo User information.
   */
  private populateUserInfo(userInfo: CustomerDetail): void {
    this.memberInfo = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      backgroundColor: userInfo.backgroundColor,
      backgroundIcon: userInfo.backgroundIcon,
      isUnderAge: userInfo.isUnderAge,
    };

    if (userInfo) {
      this.memberInfo.firstName;
      this.memberInfo.lastName = null;
      this.memberInfo.initials = this.memberInfo.firstName.slice(0, 1);
    } else {
      this.memberInfo.initials = `${
        this.memberInfo.firstName ? this.memberInfo.firstName.slice(0, 1) : ''
      } ${
        this.memberInfo.lastName ? this.memberInfo.lastName.slice(0, 1) : ''
      }`;
    }

    if (!this.memberInfo.isUnderAge) {
      this.mainMenuItems.splice(2, 0, {
        iconName: MiscIcons.Leaf,
        label: 'Pay Bills',
        route: 'udb.billPay.welcome',
      });
    } else {
      this.tabs.splice(2, 1);
    }

    this.rootScope['userInfo'] = angular.copy(this.memberInfo);
    this.scope.$broadcast('userInfoLoaded');
    this.olbEventService.emit(OlbEvents.UserInfoLoaded);
  }

  /** Shows a modal warning the user that a forced logout is about to happen due to inactivity */
  private showUserInactivityModal(): void {
    const modalInstance = this.uibModal.open({
      templateUrl: 'layout/timeout-modal/timeout-modal.tpl.html',
      windowClass: 'modal-service',
      backdrop: 'static',
      keyboard: false,
      controller: 'TimeOutModalController',
      controllerAs: '$tom',
      resolve: {
        userInactivityTimeout: () => {
          return +this.appSettings.find(setting => {
            return setting.name === 'TimeoutPopup';
          }).value;
        },
      },
    });

    modalInstance.result
      .then(() => {
        this.stayLoggedIn();
      })
      .catch(() => {
        /** If token has less than half the total lifetime remaining (I'm going to assume the token hasn't been refreshed recently), and the timeout countdown runs out, end session */
        if (!this.cookieHelper.tokenHasEnoughLifetime()) {
          this.logout(true);
        }
      });
  }

  private showOutdatedContactInfoModal(self: LayoutController) {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['showOutdatedContactInfoModalPromise'] = deferred;
    try {
      if (self.featureFlagService.isOutdatedContactInfoFlagActive()) {
        if (
          sessionStorage.getItem('outdatedContactInfoRemembered') &&
          sessionStorage.getItem('outdatedContactInfoRemembered') !== 'false'
        ) {
          deferred.resolve(true);
        } else {
          this.subSink.sink = this.store
            .select(getUserInfoIsOutdated)
            .pipe(
              switchMap(isOutdated => {
                if (isOutdated === null) {
                  return this._outdatedContactInfoService.isUserInfoOutdated();
                }

                return of(isOutdated);
              })
            )
            .subscribe(isOutdated => {
              if (
                isOutdated &&
                this.rootScope['dashboardModalsCanBeDisplayed'] &&
                !this.rootScope['welcomePagePriority'] &&
                !this.personalInformationModalAlreadyDisplayed
              ) {
                // Outdated modal open
                self.uibModal
                  .open({
                    backdrop: 'static',
                    controller: 'OutdatedContactInfoModalController',
                    controllerAs: '$modal',
                    templateUrl:
                      'layout/outdated-contact-info-modal/outdated-contact-info-modal.tpl.html',
                    windowClass: 'modal-service',
                  })
                  .closed.then(() => {
                    deferred.resolve(true);
                    self.setOutdatedContactInfoFlag();
                  });
                this.personalInformationModalAlreadyDisplayed = true;
              } else {
                deferred.resolve(true);
              }
            });
        }
      } else {
        deferred.resolve(true);
      }
    } catch (error) {
      self.serviceHelper.errorHandler(error);
    }

    return promise;
  }

  /** Disables/enables the tabs allowed depending on the user restrictions */
  private setTabRestriction(restrictions: number[]): void {
    if (!restrictions) return;

    this.rootScope['userRestrictions'] = restrictions;

    if (this.accounts) {
      this.markTabsAsShown(restrictions);

      return;
    }

    this.rootScope.$on('accountsLiteLoaded', () => {
      this.markTabsAsShown(restrictions);
    });
  }

  // Hack to add active class to menus if any sub-state is selected
  private setActiveMenuItem(): void {
    this.tabs.forEach(tab => {
      if (tab.parent || tab.newVersion) {
        tab.isActive =
          this.state.includes(tab.parent) ||
          this.state.includes(tab.newVersion);
      } else {
        tab.isActive = this.state.is(tab.ref);
      }
    });
  }

  // Retrieve the list of holidays for the next 13 months
  private loadHolidays(): void {
    this.rootScope['holidays'] = [];
    this.transactionService
      .GetHolidayDates()
      .then(res => {
        res.data.dates.forEach((value: string) => {
          this.rootScope['holidays'].push(moment(value).valueOf());
        });
      })
      .catch(this.serviceHelper.errorHandler);
  }

  // Get the brand-specific values for phones, footer links, among others
  private loadBrandProperties(brandId: number, brand: string): void {
    this.brandPropertyService
      .getBrandProperties(brandId, brand)
      .subscribe(() => {
        this.phoneNumber = this.rootScope['brandProperties'][
          'Contact_PhoneNumber'
        ];
      }, this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }

  // Sets the position of the delayed message
  private delayedMessagePosition(): void {
    this.adjustView = false;
    for (let i = 0; i < this.maxAttemps; i++) {
      this.timeoutService(() => {
        this.adjustView = true;
        const hasMenu = $('.load-views');
        const contentDelayed = $('.delayed-accounts');

        const hasMessage = hasMenu.length && this.hasOdsAccounts;
        hasMenu.toggleClass('has-message', hasMessage);
        contentDelayed.toggleClass('delayed-absolute', hasMessage);
      }, 250 * i);
    }
  }

  // Returns whether the user has accounts available for BilPay or not
  private verifyAccountsValidForBillPay(): boolean {
    return (
      this.accounts.depositAccounts.length &&
      this.accounts.depositAccounts.some(x => x.canPayFromBillPay)
    );
  }

  // Verify if user has accounts without funds
  private verifyAccountsWithoutFunds(accounts: OlbAccount[]): void {
    const currentPromise = this.rootScope['accountsWithOutFundsPromise'];

    const accountNeedsToBeFunded = accounts.filter(acc => acc.withoutFunds)[0];
    if (
      (accountNeedsToBeFunded && !this.isRiaUser) ||
      (accountNeedsToBeFunded &&
        this.rootScope['dashboardModalsCanBeDisplayed'] &&
        !this.rootScope['welcomePagePriority'])
    ) {
      this.showFunding(accountNeedsToBeFunded);
      this.setFundFlag();
    } else {
      currentPromise.resolve(true);
    }
  }

  // Show modal funding
  private showFunding(account: OlbAccount): void {
    const currentPromise = this.rootScope['accountsWithOutFundsPromise'];
    const bodyText = this.getFundingBodyText(account);

    this.modalService
      .show(
        { keyboard: false },
        {
          hasIcon: true,
          icon: 'bofi-warning',
          hasHeaderText: false,
          okText: 'Fund My Account',
          cancelText: 'Remind Me Later',
          hasClose: true,
          hasCancelButton: true,
          bodyText: `<h3>Funding Reminder</h3>
          <div class='funding__body'>${bodyText}
          </div>`,
          okButtonClass: 'uk-btn solid secondary lg funding__buttons-button',
          cancelButtonClass:
            'uk-btn outline primary lg funding__buttons-button',
          containerButtonsClass: 'funding__buttons',
        }
      )
      .then(() => {
        this.logFundingEvent(account);
        this.ngRedux.dispatch(FundingActions.turnOnFunding());
        this.store.dispatch(turnOnFunding());
        this.ngRedux.dispatch(FundingActions.setupAccounts([account]));
        this.store.dispatch(setupAccountsToBeFunded({ payload: [account] }));
        this.state.go('udb.funding', { flow: this.flowTypeEnum.Funding });
      })
      .catch(() => {
        this.logFundingEvent(account, false);
        currentPromise.resolve(true);
      });
  }

  private isInvestorCheckingAccount(account: OlbAccount): boolean {
    return account.productCode === 'X2' && account.productType === 'Checking';
  }

  /**
   * This workaround is needed because the backend always fills the nickname of the account with a value like:
   * <name/nickname> **####
   * where #### are the last four digits of the account number.
   * Example input: Checking **0298.
   * This function extracts only the <name/nickname> part, which is the real name/nickname of the account.
   * Example result for example input: Checking.
   * @param nickname
   * @param accountNumberLastDigits
   * @returns real name/nickname
   */
  private getRealAccountNameOrNickname(
    nickname: string,
    accountNumberLastDigits: string
  ): string {
    if (nickname == null) return nickname;
    const substringToRemove = `**${accountNumberLastDigits}`;
    nickname = nickname.replace(substringToRemove, '');
    nickname = nickname.trim();
  }

  private getFundingBodyText(account: OlbAccount): string {
    let bodyText: string;
    if (this.isInvestorCheckingAccount(account)) {
      const accountNumberLastDigits =
        account.accountNumber.length > 4
          ? account.accountNumber.substring(account.accountNumber.length - 4)
          : account.accountNumber;
      let accountNameOrNickname = this.getRealAccountNameOrNickname(
        account.nickname,
        accountNumberLastDigits
      );
      if (!accountNameOrNickname) {
        accountNameOrNickname = account.name;
      }

      bodyText = `Your newly opened account <b>${accountNameOrNickname} - ${accountNumberLastDigits}</b> has not been funded. </br>Please fund your account.`;
    } else {
      bodyText =
        this.env.facingBrandId === FacingBrand.UFB
          ? `Funding your account <b>${account.nickname}</b> is easy! If you have not already done so, please`
          : `Your account <b>${account.nickname}</b> has no funds. Please`;

      bodyText += ` fund your account within ${
        account.remainingDaysForFund
      } day${account.remainingDaysForFund > 1 ? 's' : ''} to
      keep your account open.`;
    }

    return bodyText;
  }

  // Show modal micro deposit
  private showMicroDepositModal(
    externalAccount: ExternalAccount,
    promiseResponse: boolean
  ): void {
    const currentPromise = this.rootScope['microDepositPromise'];

    const message = `Two small deposits have been sent to your ${externalAccount.bankName} account and it is now ready for verification.`;

    this.modalService
      .show(
        { keyboard: false },
        {
          hasIcon: true,
          icon: 'bofi-warning',
          hasHeaderText: false,
          okText: 'Verify My Account',
          cancelText: 'Remind Me Later',
          hasClose: true,
          hasCancelButton: true,
          bodyText: `<h3>External Account Verification</h3>
          			<div class='funding__body'>${message}</div>`,
          okButtonClass: 'uk-btn solid secondary lg funding__buttons-button',
          cancelButtonClass:
            'uk-btn outline primary lg funding__buttons-button',
          containerButtonsClass: 'funding__buttons',
        }
      )
      .then(() => {
        this.logMicroDepositEvent(externalAccount, 'Verify My Account');
        this.state.go('udb.accounts.dashboard', { param: 'transferOnly' });
        currentPromise.resolve(promiseResponse);
      })
      .catch(() => {
        this.logMicroDepositEvent(externalAccount, 'Remind Me Later');
        sessionStorage.removeItem('externalAccountInProcess');
        currentPromise.resolve(promiseResponse);
      });
  }

  private showBeneficiariesModal(
    deferred: ng.IDeferred<any>,
    accountId: number,
    taxPlanCif: string,
    taxPlanType: number
  ) {
    const title = `You have not named any beneficiaries`;
    const message = `Adding beneficiaries to your account ensures your assets go where you want.`;
    const dialogData = new DialogData({
      title,
      cancelText: 'Remind Me Later',
      okText: 'Add Beneficiary',
      content: `<div class='funding__body'>${message}</div>`,
      icon: UtilityIcons.Bell,
    });

    this.dialogService
      .open(dialogData)
      .afterClosed()
      .pipe(
        finalize(() => {
          deferred.resolve();
        })
      )
      .subscribe(addBeneficiary => {
        if (addBeneficiary) {
          if (taxPlanType) {
            this.state.go('udb.accounts.beneficiaries', { id: accountId });
          } else {
            this.rootScope['accountContainer-tab'] = 2;
            this.state.go('udb.accounts.container.beneficiaries', {
              id: accountId,
              type: 'Trading',
              subtitle: 'Self-Directed IRA',
            });
          }
        } else {
          this.accountsService
            .diraPrimaryBeneficiaryDismissed(taxPlanCif, taxPlanType)
            .then();
        }
      });
  }

  /**
   * Logs user interaction with the funding modal
   * @param account account that would be founded
   * @param fundNow true if user decides that the account will be founded immediately or later otherwise
   */
  private logFundingEvent(account: OlbAccount, fundNow = true): void {
    this.logUserInteraction(
      'FundingInterceptDisplayed',
      `Related account: ${account.nickname}`,
      `${account.remainingDaysForFund} days remaining`,
      `Customer selected: ${fundNow ? 'Fund' : 'Remind me later'}`
    );
  }

  private logMicroDepositEvent(
    externalAccount: ExternalAccount,
    userActionDescription: string
  ): void {
    this.logUserInteraction(
      'MicroDepositIntercept',
      `${externalAccount.bankName} **${externalAccount.accountMask}`,
      null,
      `Customer selected: ${userActionDescription}`
    );
  }

  private logDeepLinkEvent(toExternal: boolean, toUrl: string) {
    this.logUserInteraction(
      toExternal ? 'ExternalRedirect' : 'InternalRedirect',
      toUrl,
      null,
      null
    );
  }

  private logUserInteraction(
    userActionName: string,
    fieldActionName: string,
    toDetails?: string,
    fromDetails?: string
  ): void {
    this.accountsService.logUserAction(
      new UserAction(userActionName, [
        new UserActionField(fieldActionName, toDetails, fromDetails),
      ])
    );
  }

  private showBlastMessages(): void {
    const blastMessagesShown = this.getBlastMessagesShown();
    const currentPromise = this.rootScope['blastMessagesPromise'];

    if (
      blastMessagesShown.length > 0 &&
      !blastMessagesShown.some((bm: BlastMessage) => !bm.displayed)
    ) {
      currentPromise.resolve();

      return;
    }

    if (blastMessagesShown.length === 0) {
      this.blastMessagesService
        .getBlastMessages(+BlastMessageType.Popup)
        .then(res => {
          this.blastMessages = res.data;

          if (this.blastMessages.length > 0) {
            sessionStorage.setItem(
              'blastMessagesShown',
              JSON.stringify(this.blastMessages)
            );
            this.showBlastMessage(0);
          } else {
            currentPromise.resolve(true);
          }
        });
    } else {
      this.blastMessages = blastMessagesShown;
      this.showBlastMessage(0);
    }
  }

  private showBlastMessage(index: number): void {
    const currentPromise = this.rootScope['blastMessagesPromise'];

    if (index === this.blastMessages.length) {
      currentPromise.resolve();

      return;
    }

    const blastMessage = this.blastMessages[index];

    if (!blastMessage.displayed) {
      this.modalService
        .show(
          {
            templateUrl: 'layout/blast-message/blast-message.tpl.html',
            windowClass: 'blastmessage-modal',
            animation: false,
          },
          {
            hasIcon: true,
            icon: 'bofi-warning',
            hasHeaderText: false,
            okText: 'Close',
            hasCancelButton: false,
            hasClose: true,
            bodyText: `<div class="ql-editor">${blastMessage.message}</div>`,
          }
        )
        .then(() => {
          this.processClosedBlastMessage(blastMessage, index);
        })
        .catch(() => {
          this.processClosedBlastMessage(blastMessage, index);
        });
    } else {
      index++;
      this.showBlastMessage(index);
    }
  }

  private processClosedBlastMessage(
    blastMessage: BlastMessage,
    index: number
  ): void {
    blastMessage.displayed = true;
    this.updateBlastMessagesShown();
    index++;
    if (blastMessage.occurrences === -1) {
      this.blastMessagesService.logMessageDisplayed(blastMessage).then(() => {
        this.showBlastMessage(index);
      });
    } else {
      this.blastMessagesService
        .updateUserBlastMessage(blastMessage)
        .then(() => {
          this.showBlastMessage(index);
        });
    }
  }

  // Determines if Enable Dormant Accounts should be displayed
  private verifyEnableDormantAccounts(): void {
    const currentPromise = this.rootScope['dormantAccountsPromise'];

    const allAccounts = [
      ...this.accounts.depositAccounts,
      ...this.accounts.loanAccounts,
    ];
    const dormantAccounts = allAccounts.filter(account => account.isDormant);

    if (dormantAccounts.length > 0) {
      this.accountsService
        .activateDormantAccounts(dormantAccounts)
        .then(result => {
          const accountIds = result.data;

          if (!accountIds || accountIds.length === 0) {
            this.setDormantAccountsShownFlag();
            currentPromise.resolve();

            return;
          }

          this.accountsService.getSortedLiteAccounts().then(accountsResult => {
            const accounts = accountsResult.data;
            const currentAccounts = [
              ...accounts.depositAccounts,
              ...accounts.loanAccounts,
            ];

            dormantAccounts.forEach(da => {
              if (accountIds.some(id => id === da.id)) {
                const account = currentAccounts.find(a => a.id === da.id);
                this.cachedAccountsService.activateInternalAccount(
                  da.id,
                  account
                );
              }
            });

            this.authService.getUserRestrictions().subscribe(res => {
              if (!res.data) return;

              const restrictions = res.data.map(d => d.id);
              this.rootScope['userRestrictions'] = restrictions;
              this.markTabsAsShown(restrictions);

              this.showDormantAccounts(accountIds);
            });
          });
        })
        .catch(() => {
          this.setDormantAccountsShownFlag();
          currentPromise.resolve();
        });
    } else {
      this.setDormantAccountsShownFlag();
      currentPromise.resolve();
    }
  }

  // Shows modal Dormant Accounts
  private showDormantAccounts(accountIds: number[]): void {
    const currentPromise = this.rootScope['dormantAccountsPromise'];

    this.modalService
      .show(
        { keyboard: false },
        {
          hasIcon: true,
          icon: 'bofi-warning',
          hasHeaderText: false,
          okText: 'Ok',
          hasClose: false,
          hasCancelButton: false,
          bodyText: `<h3><b>Welcome back.</b></h3>
          <div>
            <p class="enable-dormants__body">Your ${
              accountIds.length === 1 ? 'account is' : 'accounts are'
            } active again. We made some changes while you were gone so take a moment to explore.</p>
          </div>`,
          okButtonClass: 'uk-btn solid secondary lg',
          hasRedirectLink: true,
          linkText: 'Learn how to keep your accounts active',
          linkUrl: '/Support/DepositAndLoans/DormantAccounts',
        }
      )
      .then(() => {
        this.setDormantAccountsShownFlag();
        currentPromise.resolve();
      });
  }

  // Add/Remove fund flag to show popup
  private setFundFlag(): void {
    sessionStorage.setItem('fundRemembered', 'true');
  }

  private setOutdatedContactInfoFlag(): void {
    sessionStorage.setItem('outdatedContactInfoRemembered', 'true');
  }

  // Sets the flag to determine if users has applied Signature Card

  private setMicroDepositFlag(): void {
    sessionStorage.setItem('microDepositChecked', 'true');
  }

  // Gets the BlastMessages shown flag from session
  private getBlastMessagesShown(): BlastMessage[] {
    const blastMessagesShownJson = sessionStorage.getItem('blastMessagesShown');
    const blastMessagesShown = blastMessagesShownJson
      ? JSON.parse(blastMessagesShownJson)
      : [];

    return blastMessagesShown;
  }

  // Sets the BlastMessagesShown flag
  private updateBlastMessagesShown(): void {
    sessionStorage.setItem(
      'blastMessagesShown',
      JSON.stringify(this.blastMessages)
    );
  }

  // Sets the flag to determine if users has seen Enable dormant intercept
  private setDormantAccountsShownFlag(): void {
    sessionStorage.setItem('dormantAccountsShown', 'true');
  }

  /** Mark tabs as shown.
   * @argument restrictions The user's restrictions.
   */
  private markTabsAsShown(restrictions: number[]): void {
    this.tabs.forEach(tab => {
      if (tab.restriction === RestrictionType.BillPay) {
        tab.isShown =
          !restrictions.includes(tab.restriction) &&
          this.verifyAccountsValidForBillPay();

        return;
      }

      tab.isShown = !restrictions.includes(tab.restriction);
    });

    this.validateTabsThatNeedAxosBankAccounts();
  }

  private validateTabsThatNeedAxosBankAccounts() {
    const userHasAxosBankAccounts = this.userSubtypeHelper.userHasAxosBankAccounts();
    if (!userHasAxosBankAccounts) {
      const tabsThatNeedAxosBankAccounts = [
        'menu-item-bills',
        'menu-item-deposit',
      ];
      this.tabs = this.tabs.filter(
        t => !tabsThatNeedAxosBankAccounts.includes(t.id)
      );
    }
  }

  private checkSplashScreen(): void {
    this.showSplashScreen =
      this.featureFlagService.isSplashScreenActive() &&
      !sessionStorage.getItem('splashScreenShowed');
    this.splashScreenWelcomeMessage = `${this.formatWelcomeMessageSplashScreen()}, ${
      localStorage.getItem('firstName') || this.cookieHelper.getUserFirstName()
    }.`;
    this.splashScreenLastLogin = this.formatLoginDateSplashScreen();
    sessionStorage.setItem('splashScreenShowed', 'true');

    // It comes from Enrollment
    if (this.location.path().indexOf('Dashboard') < 0) {
      this.showSplashScreen = false;
      localStorage.setItem('splashScreenComesFromEnrollment', 'true');

      const splashScreenListener = this.rootScope.$on(
        '$stateChangeSuccess',
        (_e, toState, _toParams, fromState) => {
          if (
            fromState.name.indexOf('funding') > 0 &&
            toState.name.indexOf('dashboard') > 0 &&
            localStorage.getItem('splashScreenComesFromEnrollment')
          ) {
            this.showSplashScreen = this.featureFlagService.isSplashScreenActive();
            localStorage.removeItem('splashScreenComesFromEnrollment');

            // Unsubscribe listener
            splashScreenListener();
          }
        }
      );
    }
  }

  private formatLoginDateSplashScreen(): string {
    const loginDate = localStorage.getItem('lastLogin');
    if (!loginDate || loginDate === 'null') {
      this.showSplashScreenLastLogin = false;

      return null;
    }

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZoneName: 'short',
      hour: 'numeric',
      minute: 'numeric',
    };

    const dateToFormat = new Date(loginDate);

    // ? For more information go to
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
    return new Intl.DateTimeFormat('en-US', options).format(dateToFormat);
  }

  private formatWelcomeMessageSplashScreen(): string {
    const rangeOfTimes = [
      {
        welcomeText: 'Good Morning',
        startTime: moment('04:01', 'H:mm'),
        endTime: moment('12:00', 'H:mm'),
      },
      {
        welcomeText: 'Good Afternoon',
        startTime: moment('12:01', 'H:mm'),
        endTime: moment('17:00', 'H:mm'),
      },
      {
        welcomeText: 'Good Evening',
        startTime: moment('17:01', 'H:mm'),
        endTime: moment('24:00', 'H:mm'),
      },
    ];

    const currentTime = moment(new Date());
    const result = rangeOfTimes.filter(t => {
      return currentTime.isBetween(t.startTime, t.endTime);
    })[0];

    return result ? result.welcomeText : 'Welcome Back';
  }

  private setLoanFlag(): void {
    let isLoanFeatureEnabled: boolean;
    this.loanService
      .isFeatureEnabled()
      .then(res => {
        isLoanFeatureEnabled = res.data;
      })
      .catch((err: ApiError) => {
        isLoanFeatureEnabled = false;
        this.serviceHelper.errorHandler(err, false);
      })
      .finally(() => {
        this.rootScope['isLoanFeatureEnabled'] = isLoanFeatureEnabled;
        this.rootScope['loanFlagAvailable'] = true;
        this.rootScope.$broadcast('loanFlagAvailable');
      });
  }

  private checkDeepLink(): void {
    const goAheadItem = localStorage.getItem('goAhead');
    if (goAheadItem) {
      if (goAheadItem === 'AxosInvest') {
        if (this.env.facingBrandId !== FacingBrand.Axos) return;

        const url = this.rootScope['brandProperties'].SitecoreInvestUrl;
        localStorage.removeItem('goAhead');
        this.axosInvestService.getSsoToken().subscribe(token => {
          this.windowService.open(
            `${url}?referrer_token=${token.data}&utm_source=axos_bank&utm_medium=referral&utm_campaign=axos_bank_olb`,
            '_blank'
          );
        });
        this.logDeepLinkEvent(true, '/Redirect/invest');

        return;
      }

      const goAhead = JSON.parse(goAheadItem);
      this.state.go(goAhead.name, goAhead.params);
      localStorage.removeItem('goAhead');
      this.logDeepLinkEvent(false, goAhead.name);
    }
  }

  /**
   * Generates the refer a friend link for the user, if applicable.
   * @param rafUrl Refer a friend URL. This comes from the Facing Brand Properties.
   */
  private addReferAFriendLink(rafUrl: string): void {
    if (!rafUrl) return;
    if (this.profileItems.find(x => x.label === 'Refer a Friend')) return;
    const referAFriendIndex =
      this.profileItems.findIndex(item => item.label === 'Find an ATM') + 1;
    rafUrl = rafUrl.replace('{referrer}', this.cookieHelper.getUserCIF());
    this.profileItems.splice(referAFriendIndex, 0, {
      externalUrl: rafUrl,
      iconName: MiscIcons.Present,
      label: 'Refer a Friend',
    });
  }

  private enableSwitchProfileLink(): void {
    const accountProfiles = JSON.parse(
      sessionStorage.getItem('accountProfiles')
    ) as AccountProfile[];
    // Enable Web Link
    this.isSBBActiveFlag = this.featureFlagService.isSBBActive();
    this.hasSBBUserManyProfiles = accountProfiles && accountProfiles.length > 1;

    // Enable Mobile Link
    if (this.isSBBActiveFlag && this.hasSBBUserManyProfiles) {
      const switchProfileItem = this.profileItems.find(
        x => x.label === 'Switch Profile'
      );
      const supportItemIndex =
        this.profileItems.findIndex(x => x.label === 'Support') + 1;
      if (switchProfileItem) return; // Return prev link created
      this.profileItems.splice(supportItemIndex, 0, {
        // Create link
        label: 'Switch Profile',
        action: () => this.showProfileModal(),
        iconName: NavigationIcons.ArrowsHorizontal,
      });
    }
  }

  private showMarketingInterstitialModal(self: LayoutController) {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['showMarketingInterstitialModalPromise'] = deferred;
    if (self.featureFlagService.isInterstitialServiceEnabled()) {
      self.sitecoreMarketingInterstitialService.hasContent
        .then(() => self.sitecoreMarketingInterstitialService.show({}, {}))
        .finally(() => {
          deferred.resolve(true);
        });
    } else {
      deferred.resolve(true);
    }

    return promise;
  }

  private showInterstitialServicingModal(self: LayoutController) {
    const deferred = self.qService.defer();
    const promise = deferred.promise;
    self.rootScope['showInterstitialServicingModalPromise'] = deferred;
    if (self.featureFlagService.isInterstitialServiceEnabled()) {
      self.sitecoreServicingInterstitialService.hasContent
        .then(() => {
          self.sitecoreServicingInterstitialService.show({}, {});
        })
        .finally(() => {
          deferred.resolve(true);
        });
    } else {
      deferred.resolve(true);
    }

    return promise;
  }

  private checkPfm3FeatureFlag(): void {
    this.isPfm3Active = this.featureFlagService.isPFM3FlagActive();
    this.isAccountAggregationEnhanceActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
  }

  private reloadInternalAccounts(): void {
    this.accountsService
      .getSortedAccountsBalance()
      .then((accountsWithBalance: OlbResponse<AccountsPage>) =>
        this.accountsService.getSortedAccountsIntercepts(
          accountsWithBalance.data
        )
      )
      .then((resultFromInterceptors: OlbResponse<AccountsPage>) => {
        const accountsFromInterceptors = resultFromInterceptors.data;
        this.accounts = accountsFromInterceptors;
        this.cachedAccountsService.loadInternalAccounts(
          accountsFromInterceptors
        );
        const accounts = JSON.parse(
          JSON.stringify(accountsFromInterceptors)
        ) as AccountsPage;
        this.store.dispatch(loadInternalAccounts({ payload: accounts }));
      })
      .catch(this.serviceHelper.errorHandler);
  }

  private setIsRiaUser(): void {
    this.subSink.sink = combineLatest([
      this.store.select(getAxosAdvisoryAccounts),
      this.supportViewFacade.isRIAUser$,
    ]).subscribe(([riaAccounts, isRiaUser]) => {
      this.isRiaUser = isRiaUser && riaAccounts?.length > 0;
    });
  }
  private enableContactButtonIcon() {
    combineLatest([
      this.store.select(getAxosAdvisoryAccounts),
      this.supportViewFacade.isRIAUser$,
    ]).subscribe(([riaAccounts, isRiaUser]) => {
      this.isContactButtonEnabled = !(isRiaUser && riaAccounts?.length > 0);
    });
  }
}
