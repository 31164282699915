import { Store } from '@ngrx/store';
import { IRootScopeService, IScope } from 'angular';
import { Inject } from '../../decorators/decorators';
import { BaseTile, BaseTileSettings, TileSetting } from '../base-tile';
import { TilesService } from 'services/tiles.service';
import { AxosClearingService } from 'services/axos-clearing.service';
import { CachedTradingAccountsService } from './../../services/cached-trading-accounts.service';
import { TradingAccount } from 'accounts/typings/TradingAccount';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AxosAdvisoryAccount } from '@core/models';
import { AxosAdvisoryService } from '@core/services/axos-advisory.service';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';
import { InitializePdpInputType } from '@app/Areas/AAS/features/product-details-page/facade/types';

@Inject(
  '$scope',
  '$element',
  'tilesService',
  'serviceHelper',
  '$state',
  '$rootScope',
  'axosClearingService',
  'cachedTradingAccountsService',
  'featureFlagService',
  'axosAdvisoryService',
  'ngrxStore',
  'pdpFacade'
)
export class InvestmentOverviewController extends BaseTile<
  InvestmentOverviewTileSettings
> {
  title: string;
  quickActionText: string;
  investmentAccounts: TradingAccount[] = [];
  userSavedAccount: TradingAccount;
  selectedAccount: TradingAccount;
  settings: number[] = [];
  axosAdvisoryAccounts: AxosAdvisoryAccount[] = [];
  private listeners: Function[] = [];
  private isShowTotalUnrealizedGainLossForInvestmentOverviewActive: boolean = false;

  constructor(
    scope: IScope,
    elm: ng.IRootElementService,
    tilesService: TilesService,
    serviceHelper: IServiceHelper,
    private state: ng.ui.IStateService,
    private readonly rootScope: IRootScopeService,
    private readonly axosClearingService: AxosClearingService,
    private readonly cachedTradingAccountsService: CachedTradingAccountsService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly axosAdvisoryService: AxosAdvisoryService,
    private store: Store,
    private readonly pdpFacade: PdpFacade
  ) {
    super(scope, elm, tilesService, serviceHelper);
  }

  $onInit(): void {
    if (
      this.axosClearingService.isAxosTradingActiveForUser() ||
      this.featureFlagService.isRiaPilotActive()
    ) {
      this.getSettings();
      this.listeners.push(
        this.scope.$on('investAccountsLoaded', this.setInitialData.bind(this))
      );
      if (this.rootScope['isInvestAccountsLoaded']) this.setInitialData();
      this.isInitialising = false;
    } else {
      this.isInitialising = false;
      this.title = 'AxosTrading Not Active For User';
    }
    this.isShowTotalUnrealizedGainLossForInvestmentOverviewActive = this.featureFlagService.isShowTotalUnrealizedGainLossForInvestmentOverviewActive();
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  setInitialData(): void {
    this.investmentAccounts = this.cachedTradingAccountsService.tradingAccounts
      .filter(acc => acc.statusName?.toLowerCase() === 'active')
      .map(acc => ({
        ...acc,
        displayName: `${
          acc.displayName ?? acc.nickname
        } *${acc.accountNumber.slice(-4)}`,
      }));

    if (this.featureFlagService.isRiaPilotActive()) {
      this.store
        .select(getAxosAdvisoryAccounts)
        .subscribe((riaAccounts: AxosAdvisoryAccount[]) => {
          this.axosAdvisoryAccounts = riaAccounts ? riaAccounts : [];
          riaAccounts?.forEach((element: AxosAdvisoryAccount) => {
            if (element.riaId) {
              this.investmentAccounts.push(
                new TradingAccount({
                  id: parseInt(element.riaId),
                  accountName: element.displayName,
                  amountAvailableToWithdraw: element.accountBalance,
                  accountNumber: element.accountNumber,
                  nickname: element.accountNickname,
                  displayName: element.accountNickname ?? '',
                  isRia: true,
                })
              );
            }
          });
        });
    }

    if (
      this.investmentAccounts.length > 0 ||
      this.axosAdvisoryAccounts.length > 0
    ) {
      this.title = 'Investment Overview';
      this.quickActionText = 'See Account';

      if (
        this.tileSettings.TradingAccount &&
        this.tileSettings.TradingAccount.value != null
      ) {
        let settingValue = this.tileSettings.TradingAccount.value;
        let configuredAccount =
          this.investmentAccounts.find(
            x => x.isRia && x.accountNumber == settingValue
          ) ||
          this.investmentAccounts.find(x => x.id.toString() === settingValue);
        this.userSavedAccount = configuredAccount;
        this.selectedAccount = configuredAccount;
      } else {
        this.userSavedAccount = this.investmentAccounts[0];
        this.selectedAccount = this.investmentAccounts[0];
      }
      this.riaValidation();
    } else {
      this.title = "You don't have any accounts";
    }
    this.isInitialising = false;
  }

  onCancel(): void {
    this.selectedAccount = this.userSavedAccount;
  }

  saveTileSettings(): void {
    this.tileSettings.TradingAccount.value = this.selectedAccount.isRia
      ? this.selectedAccount.accountNumber
      : this.selectedAccount.id.toString();
    this.saveSettings();
    this.userSavedAccount = this.selectedAccount;
    this.riaValidation();
  }

  redirectToAccounts(accountId?: number): void {
    if (this.isSavedAccountAnAasAccount()) {
      const aasAccount: AxosAdvisoryAccount = this.axosAdvisoryAccounts.find(
        x =>
          x.riaId === this.userSavedAccount.id.toString() &&
          x.accountNumber === this.userSavedAccount.accountNumber
      );

      const initializePdpInputType: InitializePdpInputType = {
        axosAdvisoryAccount: {
          ...aasAccount,
          displayName: aasAccount.accountDisplayName,
        },
      };
      this.pdpFacade.initializePdp(initializePdpInputType);
      this.state
        .go('udb.productDetailsPage', {
          accountNumber:
            initializePdpInputType.axosAdvisoryAccount.accountNumber,
        })
        .then(() => {
          scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
    } else {
      this.state.go('udb.accounts.container', {
        id: accountId,
        type: 'Trading',
      });
    }
  }

  isSavedAccountAnAasAccount(): boolean {
    return this.axosAdvisoryAccounts.some(
      x =>
        x.riaId === this.userSavedAccount.id.toString() &&
        x.accountNumber === this.userSavedAccount.accountNumber
    );
  }

  riaValidation() {
    if (
      this.axosAdvisoryAccounts.find(
        x =>
          x.riaId === this.userSavedAccount.id.toString() &&
          x.accountNumber === this.userSavedAccount.accountNumber
      )
    ) {
      this.axosAdvisoryService
        .getAccountInvestmentDetails(this.userSavedAccount.accountNumber)
        .subscribe(result => {
          if (result.data) {
            this.userSavedAccount.totalValue = result.data.totalValue;
            this.userSavedAccount.cashInvest = result.data.cashInvest;
            this.userSavedAccount.marketValue = result.data.marketValue;
            this.userSavedAccount.gainLoss = result.data.gainLoss;
            this.userSavedAccount.gainLossPercent =
              result.data.totalValuePercent;
            this.userSavedAccount.dayChangePercent =
              result.data.marketValuePercent;
          }
        });
    }
  }

  showTotalUnrealizedRow(): boolean {
    return (
      !this.userSavedAccount?.isRia ||
      (this.userSavedAccount?.isRia &&
        this.isShowTotalUnrealizedGainLossForInvestmentOverviewActive)
    );
  }
}

interface InvestmentOverviewTileSettings extends BaseTileSettings {
  TradingAccount?: TileSetting;
}
