import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Recipient } from '@app/bill-pay/typings';
import tippy, { Props, animateFill } from 'tippy.js';

@Component({
  selector: 'app-dropdown-amount',
  templateUrl: './dropdown-amount.component.html',
  styleUrls: ['./dropdown-amount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownAmountComponent {
  @Input() recipient: Recipient = {
    eBill: { amountDue: 0, outstandingBalance: 0 },
  } as Recipient;
  @Input() amount: number;
  @Input() amountOpt: number;

  @Output() amntChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() setAmmount: EventEmitter<number> = new EventEmitter<number>();
  amountDisplayed: string = '';

  @ViewChild('tooltip') tooltip: ElementRef;
  @ViewChild('dropdown') dropdown: ElementRef;
  tippyInstance: any;

  constructor(private currencyPipe: CurrencyPipe) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.amount) {
      this.amountDisplayed = this.currencyPipe.transform(this.amount, 'USD');
      this.amntChange.emit(this.amount);
    }
  }
  ngOnInit() {
    if (!this.amount) {
      this.amount = 0;
    }
    this.amountDisplayed = this.currencyPipe.transform(this.amount, 'USD');
  }

  amountChange(element: any) {
    this.amount = +this.amountDisplayed.replace(/[^0-9\.]+/g, '');
    this.amntChange.emit(this.amount);
    element.target.value = this.currencyPipe.transform(this.amount, 'USD');
  }
  setAmntValue(event: any) {
    this.amountDisplayed = event;
  }

  keyPress(event: KeyboardEvent) {
    const pattern = /^[0-9.]/;
    const inputChar = event.key;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setAmount(type: any) {
    this.tippyInstance.hide();

    this.amountOpt = type;
    switch (type) {
      case 0:
        this.recipient.eBill.amountDue > 0
          ? (this.amount = this.recipient.eBill.amountDue)
          : (this.amountOpt = 2);
        break;
      case 1:
        this.recipient.eBill.outstandingBalance > 0
          ? (this.amount = this.recipient.eBill.outstandingBalance)
          : (this.amountOpt = 2);
        break;
      default:
        this.amountOpt = 2;
        this.amount = 0;
    }

    this.amntChange.emit(this.amount);
  }

  options: Partial<Props> = {
    allowHTML: true,
    animateFill: false,
    placement: 'bottom',
    plugins: [animateFill],
    interactive: true,
    appendTo: 'parent',
    trigger: 'click',
    hideOnClick: true,
  };

  ngAfterViewInit(): void {
    this.options.content = this.dropdown?.nativeElement;
    this.tippyInstance = tippy(this.tooltip?.nativeElement, this.options);
  }
}
